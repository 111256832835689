import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../server";
import { useQuickCommerce } from "../../context/QuickCommerceProvider";

const RecTitle = {
  fontWeight: "700",
  fontSize: "18px",
  fontFamily: "quicksand",
};

const RecClear = {
  color: "#407EC7",
  fontWeight: "600",
  fontSize: "14px",
  fontFamily: "quicksand",
};

const RecommendationsItem = {
  fontWeight: "500",
  fontSize: "13px",
  fontFamily: "quicksand",
  color: "#696969",
  backgroundColor: "#F0F1F2",
  padding: "5px 6px",
  margin: "5px 6px 5px 0px",
};

const Recommendations = ({ tempQuery, setTempQuery }) => {
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const { quickCommerce } = useQuickCommerce();

  const fetchSuggestions = async () => {
    const res = await axios.get(
      `${server}/public/searchproduct?q=${tempQuery}&page=1&limit=5?productType=${quickCommerce}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    const { result, totalCount } = res.data;
    setSuggestions(result);
  };

  useEffect(() => {
    fetchSuggestions();
  }, [tempQuery]);

  return (
    <Box sx={{ padding: "0px 20px", margin: "10px 0px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={RecTitle}>Recommendations</Typography>
        <Typography sx={RecClear} onClick={() => setTempQuery("")}>
          Clear
        </Typography>
      </Box>
      <Grid container sx={{ margin: "8px 0px 10px 0px" }}>
        {tempQuery && suggestions ? (
          suggestions.map((element) => {
            return (
              <Grid
                item
                onClick={() =>
                  navigate(`/productdetail/${element?.name}`, {
                    state: { prodid: element?._id },
                  })
                }
              >
                <Typography sx={RecommendationsItem}>{element.name}</Typography>
              </Grid>
            );
          })
        ) : tempQuery ? (
          <>No Results Found</>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  );
};

export default Recommendations;
