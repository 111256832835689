import { Box, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DefaultImage from "../../../../assets/defaultimg.jpg";

const categoryText = {
  textAlign: "center",
  color: "black",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "13px",
  alignSelf: "center",
  display: "-webkit-box", // Flexbox with Webkit support
  WebkitLineClamp: 2, // Clamp the text to 2 lines
  WebkitBoxOrient: "vertical", // Vertical box orientation
  overflow: "hidden", // Hide overflow
  textOverflow: "ellipsis", // Add ellipsis at the end
  whiteSpace: "normal", // Allow wrapping to the next line
};

const CategoryCardDiv = {
  // background: "#fff",
  // boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25);",
  // borderRadius: "5px",
  // maxWidth: "130px",
  // height: "100%",
  // maxHeight: "130px",
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "space-between",
  // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
  // borderRadius: "5px",

  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25);",
  borderRadius: "5px",
  width: "30vw", // Set width and height to be the same
  height: "30vw", // Height same as width
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
  borderRadius: "5px",
  overflow: "hidden", // Prevent overflow from content
};

const CategoryCard = (props) => {
  // useEffect(() => {
  // 	console.log(props.categoryname + " " + props.categoryimage )
  // }, []);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    isLoaded && props.setLoadCount && props.setLoadCount((prev) => prev + 1);
  }, [isLoaded, props.setLoadCount]);
  return props.propid ? (
    <Link
      to={`/categoryproducts/${props.categoryname}/${props.subcategory.name}`}
      // to={`/categoryproducts/${props.categoryname}/${props.categoryid}/${props.propid}`}
      state={{
        categoryId: props?.categoryid,
        subcategoryId: props.subcategory?._id,
        name: props.subcategory?.name,
      }}
      style={{
        textDecoration: "none",
        color: "black",
        // border: "1px solid #E8E8E8",
        borderRadius: "12px",
      }}
    >
      <Box sx={{ CategoryCardDiv, margin: "1vh 0" }}>
        <img
          src={
            props.categoryimage === "noimage"
              ? DefaultImage
              : props.categoryimage
          }
          alt="CategoryImages"
          style={{
            width: "100%",
            height: "100%",
            padding: "0px 0px 0px 0px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: "5px",
          }}
          onLoad={() => setIsLoaded(true)}
          loading="lazy"
        />
        {!isLoaded && (
          <Skeleton
            style={{
              width: "28vw",
              height: "28vw",
            }}
          />
        )}
      </Box>
      <Typography variant="subtitle1" sx={categoryText}>
        {props.categoryname}
      </Typography>
    </Link>
  ) : (
    <Link
      to={`/subcategory/${props.categoryid}`}
      style={{ textDecoration: "none" }}
    >
      <Box
        sx={{
          CategoryCardDiv,
          // ...(!props.propid && { marginTop: "1vh" }),
        }}
      >
        <img
          src={
            props.categoryimage === "noimage"
              ? DefaultImage
              : props.categoryimage
          }
          alt="CategoryImages"
          style={{
            width: "100%",
            objectFit: "fill",
            // maxWidth: "130px",
            // maxHeight: "130px",
            height: "100%",
            padding: 0, //"0px 0px 0px 0px",
          }}
          onLoad={() => setIsLoaded(true)}
          loading="lazy"
        />
        {!isLoaded && (
          <Skeleton
            style={{
              width: "40vw",
              height: "40vw",
            }}
          />
        )}
      </Box>
      <Typography variant="subtitle1" sx={categoryText}>
        {props.categoryname}
      </Typography>
    </Link>
  );
};

export default CategoryCard;
