import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import server from "../../../server";
import ProductCard from "./ProductCard";

const ViewAllLink = { textDecoration: "none", color: "black" };
const CarouselBox = { overflow: "auto", padding: "10px 10px 10px 10px" };
const TitleText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
};
const SectionBox = { paddingBottom: "10px", height: "fit-content" };
const SectionBoxInner = {
  backgroundColor: "#fff",
  padding: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const PreviouslyBought = () => {
  const [products, setProducts] = useState([]);

  const fetchproducts = async () => {
    try {
      const result = await axios.get(`${server}/orders/recent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(result.data);
      setProducts(result?.data.products.map((p) => p?.product || p));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchproducts();
  }, []);

  return (
    <Box sx={SectionBox}>
      <Box sx={SectionBoxInner}>
        <Typography variant="subtitle1" sx={TitleText}>
          Previously Bought
        </Typography>
        <Link to="/orders/recent/Previously Bought" style={ViewAllLink}>
          <Typography
            variant="subtitle2"
            sx={{ color: "#407EC7", fontSize: "12px", fontWeight: 500 }}
          >
            View All
          </Typography>
        </Link>
      </Box>

      {Boolean(products.length) ? (
        <Box style={CarouselBox}>
          <Grid container spacing={2} wrap="nowrap">
            {products.map((product) => (
              <Grid item key={product._id}>
                <ProductCard proddetails={product} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Skeleton style={{ width: "40vw", height: "400px" }} />
        </Box>
      )}
    </Box>
  );
};

export default PreviouslyBought;
