import { createContext, useContext, useState } from "react";

export const QuickCommerceContext = createContext();

export const QuickCommerceProvider = ({ children }) => {
  const [quickCommerce, setQuickCommerce] = useState("QuickCommerce");

  return (
    <QuickCommerceContext.Provider value={{ quickCommerce, setQuickCommerce }}>
      {children}
    </QuickCommerceContext.Provider>
  );
};

export const useQuickCommerce = () => useContext(QuickCommerceContext);
