import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import server from "../../../src/Components/server";

const fetchProductDetails = async () => {
  const cart = JSON.parse(localStorage.getItem("cart"));
  const prodData = {};
  for (const cartItem of cart) {
    const result = await axios.get(`${server}/public/products/${cartItem[0]}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    prodData[cartItem[0]] = result.data.product;
  }
  return prodData;
};

export const fetchData = createAsyncThunk("data/fetchData", async () => {
  const response = await fetchProductDetails();
  return response;
});

const dataSlice = createSlice({
  name: "data",
  initialState: { items: [], status: "idle" },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default dataSlice.reducer;
