import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import TrolleyModal from "./Layout/TrolleyModal";
import TrolleyTable from "./Layout/TrolleyTable";

const Trolley = () => {
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const { setLoading } = context;
  const [trolleys, setTrolleys] = useState([]);
  const [open, setOpen] = useState(false);

  const [trolleyInformation, setTrolleyInformation] = useState({
    qrCode: "",
    isEngaged: false,
    physicalWeight: "0",
    virtualCartWeight: "0",
  });

  const createTrolley = async () => {
    try {
      const result = await axios.post(
        `${server}/trolley/create`,
        {
          trolleyInformation,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      setTrolleys((prevTrolleys) => [...prevTrolleys, result.data]);
      setTrolleyInformation("");
      toast.success("Trolley created successfully!");
    } catch (error) {
      // Check if the error response exists and has data
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Show the error message in a toast
        toast.error(error.response.data.message);
      } else {
        // Handle other errors (like network issues)
        toast.error("Something went wrong. Please try again.");
      }

      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Trolley Information is ", trolleyInformation);
    await createTrolley();
    setTrolleyInformation("");
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setTrolleyInformation("");
    setOpen(false);
  };

  const getAllTrolleys = async () => {
    try {
      const result = await axios.get(`${server}/trolleys`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTrolleys(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      setLoading(true);
      getAllTrolleys();
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Trolley Summary</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{
            marginBottom: "10px",
            marginRight: "10px",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          Create
        </Button>
      </div>

      <TrolleyModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        trolleyInformation={trolleyInformation}
        setTrolleyInformation={setTrolleyInformation}
      />
      <div>
        <TrolleyTable
          rows={trolleys}
          setRows={setTrolleys}
          trolleys={trolleys}
        />
      </div>
    </div>
  );
};

export default Trolley;
