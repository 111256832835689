import { Box, LinearProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Star } from "../../../../assets/Star.svg";
import server from "../../../server";

const ReviewCount = {
  color: "#FFC107",
  display: "flex",
  alignItems: "center",
  fontFamily: "quicksand",
};

const ReviewCountText = { fontFamily: "quicksand" };

const ReviewCard = (props) => {
  const [reviewinfo, setreviewinfo] = useState([]);
  const [avgreview, setavgreview] = useState(0);
  const [reviewnums, setnums] = useState([]);
  const getreviewdetails = async () => {
    const result = await axios.get(
      `${server}/public/products/${props.prodid}/reviews`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    // console.log(result.data)
    if (result.data.length !== 0) {
      let rating_one = 0;
      let rating_two = 0;
      let rating_three = 0;
      let rating_four = 0;
      let rating_five = 0;
      // console.log( 'for')
      setreviewinfo(result.data);
      let avg = 0;
      result.data.forEach((review) => {
        switch (review.rating) {
          case 1:
            rating_one++;
            break;

          case 2:
            rating_two++;
            break;

          case 3:
            rating_three++;
            break;

          case 4:
            rating_four++;
            break;

          case 5:
            rating_five++;
            break;
        }
        avg += review.rating;
      });
      setavgreview(parseFloat(avg / result.data.length).toFixed(1));
      setnums([rating_one, rating_two, rating_three, rating_four, rating_five]);
    }
  };

  useEffect(() => {
    getreviewdetails();
  }, [props.prodid]);

  // useEffect(() => {
  //   // console.log(reviewnums)
  // }, [reviewnums])

  return (
    <Box sx={{ padding: "10px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={ReviewCount}>
            <Typography sx={ReviewCountText} variant="h5">
              {avgreview}
            </Typography>
            <Star style={{ color: "#FFC107", margin: "0px 5px" }} />
          </Box>
          <Typography
            variant="caption"
            sx={{ color: "#4B4B4B", fontWeight: "600" }}
          >
            {reviewinfo.length} reviews
          </Typography>
        </Box>
        <Box>
          <Link
            to={`/review/${props.prodid}`}
            state={{ prodid: props.prodid }}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#407EC7",
                fontFamily: "poppins",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              View All Reviews
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box>
        <hr />
        {reviewnums &&
          reviewnums.map((element, i) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: "10px",
                    color: "rgba(78, 78, 78, 0.6)",
                    fontSize: "14px",
                  }}
                >
                  {reviewnums.length - i}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    (reviewnums[reviewnums.length - i - 1] /
                      reviewinfo.length) *
                    100
                  }
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#FFB400",
                    },
                    height: "8px",
                    width: "80%",
                  }}
                />
                <Typography
                  sx={{
                    paddingRight: "10px",
                    color: "rgba(78, 78, 78, 0.6)",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  {reviewnums[reviewnums.length - i - 1]}
                </Typography>
              </Box>
            );
          })}
        {/* <Grid >
          <Grid xs item >
          </Grid>
          <Grid xs item>
            4<LinearProgress variant="determinate" value={(reviewnums[3] / reviewinfo.length) * 100} sx={{
              backgroundColor: 'grey',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#FFB400'
              },
              height: "8px"

            }} />
            {reviewnums[3]}

          </Grid>
          <Grid xs item>
            3<LinearProgress variant="determinate" value={(reviewnums[2] / reviewinfo.length) * 100} sx={{
              backgroundColor: 'grey',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#FFB400'
              },
              height: "8px"

            }} />
            {reviewnums[2]}

          </Grid>
          <Grid xs item>
            2<LinearProgress variant="determinate" value={(reviewnums[1] / reviewinfo.length) * 100} sx={{
              backgroundColor: 'grey',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#FFB400'
              },
              height: "8px"

            }} />
            {reviewnums[1]}

          </Grid>
          <Grid xs item>
            1<LinearProgress variant="determinate" value={(reviewnums[0] / reviewinfo.length) * 100} sx={{
              backgroundColor: 'grey',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#FFB400'
              },
              height: "8px"

            }} />
            {reviewnums[0]}

          </Grid>
        </Grid> */}
        {/* 5<LinearProgress variant="determinate" value={(reviewnums[4] / reviewinfo.length) * 100} sx={{
          height: "10px"
        }} />
        4<LinearProgress variant="determinate" value={(reviewnums[3] / reviewinfo.length) * 100} />
        3<LinearProgress variant="determinate" value={(reviewnums[2] / reviewinfo.length) * 100} />
        2<LinearProgress variant="determinate" value={(reviewnums[1] / reviewinfo.length) * 100} />
        1<LinearProgress variant="determinate" value={(reviewnums[0] / reviewinfo.length) * 100} />
 */}
      </Box>
      {/* <Box></Box> */}
    </Box>
  );
};

export default ReviewCard;
