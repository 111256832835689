import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import server from "../../../server";
import { useQuickCommerce } from "../../context/QuickCommerceProvider";
import "../../Scroll.css";

function CategoryCarousel(props) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 350 },
      items: 3,
    },
    smallMobile: {
      breakpoint: { max: 350, min: 0 },
      items: 2,
    },
  };

  const [brands, setBrands] = useState([]);
  const [clicked, setClicked] = useState([]);
  const { quickCommerce } = useQuickCommerce();

  //change this to get subcategories by category id
  const getbrands = async () => {
    const result = await axios.get(
      `${server}/public/subcategory/find/${props.categoryid}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setBrands(result.data);
    let arr = Array(result.data.length).fill(false);
    for (let j = 0; j < result.data.length; j++) {
      if (result.data[j]._id === props.subcatid) arr[j] = true;
    }

    setClicked(arr);
    // console.log(result.data)
  };

  const getprodbybrand = async (e, brandid, brandname) => {
    e.preventDefault();
    // console.log(props.categoryid)
    const result = await axios.get(
      `${server}/public/products/bySubCategoryId/${brandid}?productType=${quickCommerce}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result.data)
    props.bybrand(
      result.data.filter((item) => item.published == true),
      brandname,
    );
  };

  useEffect(() => {
    try {
      getbrands();
    } catch (err) {}
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: "#F2F2F2",
      }}
    >
      {brands &&
        brands.map((currbrand, i) => {
          return (
            <Box
              key={i}
              onClick={(e) => {
                let arr = clicked;
                for (let j = 0; j < arr.length; j++) arr[j] = false;
                arr[i] = true;
                setClicked(arr);
                getprodbybrand(e, currbrand._id, currbrand.name);
              }}
              sx={{
                textTransform: "none",
                margin: "5px",
                // width:"auto"
              }}
            >
              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "9px",
                  // backgroundColor: "#fff",
                  backgroundColor:
                    clicked.length > 0
                      ? clicked[i]
                        ? "rgba(94, 196, 1, 1)"
                        : "#fff"
                      : "#fff",
                  color:
                    clicked.length > 0
                      ? clicked[i]
                        ? "#fff"
                        : "black"
                      : "black",
                  padding: "10px",
                  textAlign: "center",
                  width: "auto",
                  whiteSpace: "nowrap",
                  // color: "black",
                }}
              >
                {currbrand.name}
              </Typography>
            </Box>
          );
        })}
      {/* // </Carousel> */}
      {/* // </StyledEngineProvider> */}
    </Box>
  );
}

export default CategoryCarousel;
