import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../server";
import Banner from "./Layout/Banner";
// import FeaturedProducts from "./Layout/FeaturedProducts";
import Header from "./Layout/Header";
import SimilarProducts from "./Layout/SimilarProducts";
// import TrendingNow from "./Layout/TrendingNow";
import { subcatsToDisplay_16_9 } from "./constants";
import ViewCategory from "./Layout/ViewCategory";
// import { fileNames_16_9, subcatsToDisplay_16_9 } from "./constants";
import PreviouslyBought from "./Layout/PreviouslyBought";
import Wishlist from "./Layout/WishList";

const Home = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState();
  const [openSection, setOpenSections] = useState({});
  const [categoriesBySequence, setCategoriesBySequence] = useState([]);
  const [prevProductsExists, setPrevProductsExist] = useState();
  const [wishlistExists, setWishListExists] = useState();

  const getbanners = async () => {
    const result = await axios.get(`${server}/public/banners`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setItems(
      result.data.filter((item) => {
        return item.active;
      }),
    );
  };
  const getOpenSections = async () => {
    try {
      const result = await axios.get(`${server}/public/subcategories/section`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setOpenSections({
        section_1: result?.data?.section_1,
        section_2: result?.data?.section_2,
      });
    } catch (err) {}
    // console.log(result.data);
  };

  const getCategoriesBySequence = async () => {
    try {
      const result = await axios.get(`${server}/public/sequence/Category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setCategoriesBySequence(result.data);
      console.log("result .data ", result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchproducts = async () => {
    try {
      const result = await axios.get(`${server}/orders/recent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log("fetchproduct result is ", result.data);
      if (result?.data?.products?.length > 0) {
        setPrevProductsExist(true);
      }
      // setProducts(result?.data.products.map((p) => p?.product || p));
    } catch (error) {
      console.log(error);
    }
  };

  const getWishes = async () => {
    const result = await axios.get(`${server}/user/wishlist/populated`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    console.log("wishes result ", result);
    if (result?.data?.products?.length > 0) {
      setWishListExists(true);
    }

    // setWishlistDetails(result?.data.products.map((p) => p?.product || p));
  };

  useEffect(() => {
    // if (!localStorage.getItem("user")) navigate("/");
    // else getbanners() && getOpenSections();
    getbanners() && getOpenSections();
    getCategoriesBySequence();
    fetchproducts();
    getWishes();
  }, []);

  const [toLoad, setToLoad] = useState([0, 1]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Header />

      <Banner
        min={0}
        max={10}
        items={items}
        index={1}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      {/* <CarouselHome /> */}
      {/* <FeaturedProducts /> */}
      {localStorage.getItem("user") && (
        <>
          {prevProductsExists && <PreviouslyBought />}
          {wishlistExists && <Wishlist />}
        </>
      )}
      {/* <ViewCategory index={1} toLoad={toLoad} setToLoad={setToLoad} /> */}

      {categoriesBySequence.slice(0, 4).map((category, index) => (
        <ViewCategory
          key={category._id} // Assuming category has an _id field
          catid={category._id}
          toDisplay={subcatsToDisplay_16_9[index]} // Assuming you have a corresponding array
          bannerImg={category.imageUrl} // Adjust according to your logic
          index={index + 1} // Update index as necessary
          toLoad={toLoad}
          setToLoad={setToLoad}
        />
      ))}

      <SimilarProducts
        subCategoryId="6543c1fdf7e3ae87941ad608"
        index={5}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="6543c214f7e3ae87941ad773"
        index={6}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <Banner
        min={11}
        max={20}
        items={items}
        index={7}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      {console.log("current indesbefore ", toLoad)}
      {categoriesBySequence.slice(4, 6).map((category, index) => (
        <ViewCategory
          key={category._id} // Assuming category has an _id field
          catid={category._id}
          toDisplay={subcatsToDisplay_16_9[index]} // Assuming you have a corresponding array
          bannerImg={category.imageUrl} // Adjust according to your logic
          index={index + 8} // Update index as necessary
          toLoad={toLoad}
          setToLoad={setToLoad}
        />
      ))}
      {console.log("current indes ", toLoad)}
      <SimilarProducts
        subCategoryId="6543c1f7f7e3ae87941ad59b"
        index={10}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="65ccd7f1eca383e82c9334a4"
        index={11}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        // subCategoryId="6543c1fdf7e3ae87941ad608"
        subCategoryId="6543c21bf7e3ae87941ad7e4"
        index={12}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      {/* <SimilarProducts
        // subCategoryId="6543c1fdf7e3ae87941ad608"
        subCategoryId="6543bfd1f7e3ae87941ab66f"
        index={12}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <SimilarProducts
        // subCategoryId="6543c1fdf7e3ae87941ad608"
        subCategoryId="668bfc43fb10cd66f6cb2e69"
        index={9}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        // subCategoryId="6543c1fdf7e3ae87941ad608"
        subCategoryId="668bfc43fb10cd66f6cb2e69"
        index={13}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* below is the old code of the Home page  */}
      {/* <ViewCategory
        catid="6543c0c9f7e3ae87941ac44d"
        toDisplay={subcatsToDisplay_16_9[0]}
        bannerImg={fileNames_16_9[2]}
        index={1}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6543bfd1f7e3ae87941ab66f"
        toDisplay={subcatsToDisplay_16_9[1]}
        bannerImg={fileNames_16_9[9]}
        index={2}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6495da143508331f0b71bfd9"
        toDisplay={subcatsToDisplay_16_9[2]}
        bannerImg={fileNames_16_9[1]}
        index={3}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <TrendingNow
        openSectionData={openSection.section_1}
        index={4}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6543c0c9f7e3ae87941ac44d"
        toDisplay={subcatsToDisplay_16_9[3]}
        bannerImg={fileNames_16_9[7]}
        index={5}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6495da143508331f0b71bfd9"
        toDisplay={subcatsToDisplay_16_9[4]}
        bannerImg={fileNames_16_9[4]}
        index={6}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6495da143508331f0b71bfd9"
        toDisplay={subcatsToDisplay_16_9[5]}
        bannerImg={fileNames_16_9[6]}
        index={7}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <TrendingNow
        openSectionData={openSection.section_2}
        index={8}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6543c014f7e3ae87941aba4d"
        toDisplay={subcatsToDisplay_16_9[6]}
        bannerImg={fileNames_16_9[5]}
        index={9}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <Banner
        min={10}
        max={20}
        items={items}
        index={10}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <ViewCategory
        catid="6543c014f7e3ae87941aba4d"
        toDisplay={subcatsToDisplay_16_9[7]}
        bannerImg={fileNames_16_9[3]}
        index={11}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="6543c014f7e3ae87941aba4d"
        toDisplay={subcatsToDisplay_16_9[8]}
        bannerImg={fileNames_16_9[9]}
        index={12}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <hr style={{ height: "1px", borderTop: "1px solid #f00" }} /> */}
      {/* <SimilarProducts
        subCategoryId="6543c1fdf7e3ae87941ad608"
        index={13}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="6543c214f7e3ae87941ad773"
        index={14}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="6543c1f7f7e3ae87941ad59b"
        index={15}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="65ccd7f1eca383e82c9334a4"
        index={16}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="6543c21bf7e3ae87941ad7e4"
        index={17}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <SimilarProducts categoryid="6543bfd1f7e3ae87941ab66f" /> */}
      {/* the below view is for viewing products of category i.e it shows subcat cards */}
      {/* <ViewCategory catid="64919111c55b9dcf28c39354" /> */}
      {/* <ViewCategory catid="64cdd33cc472bb70b4de3786" /> */}
      {/* <CarouselHome /> */}
      {/* <ViewCategory catid="6543bfd1f7e3ae87941ab66f" /> */}
      {/* <Banner id = '64bfcc896bedd450788672c1'/> */}

      {/* <ViewCategory
        catid="6495da143508331f0b71bfd9"
        toDisplay={[]}
        bannerImg={fileNames_16_9[1]}
        index={18}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}
    </Box>
  );
};

export default Home;
