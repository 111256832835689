import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";

const TrolleyModal = ({
  open,
  handleClose,
  handleSubmit,
  trolleyInformation,
  setTrolleyInformation,
  isEdit,
}) => {
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setTrolleyInformation((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90vw", sm: "60vw", md: "40vw" },
          bgcolor: "#ffffff",
          borderRadius: "16px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            {isEdit ? "Edit Trolley" : "Create New Trolley"}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <CloseIcon sx={{ color: "#555" }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            label="Trolley QrCode"
            name="qrCode"
            value={trolleyInformation?.qrCode}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5570F1",
                },
              },
            }}
          />

          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            sx={{
              mb: 3,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5570F1",
                },
              },
            }}
          >
            <InputLabel id="test-select-label">IsEngaged</InputLabel>
            <Select
              sx={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={trolleyInformation?.isEngaged || false}
              label="Select Status"
              name="isEngaged"
              onChange={handleInputChange}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Trolley PhysicalCartWeight"
            name="physicalWeight"
            defaultValue={"0"}
            value={trolleyInformation?.physicalWeight}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5570F1",
                },
              },
            }}
          />

          <TextField
            label="Trolley VirtualCartWeight"
            name="virtualCartWeight"
            defaultValue={"0"}
            value={trolleyInformation?.virtualCartWeight}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            sx={{
              mb: 3,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5570F1",
                },
              },
            }}
          />
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            sx={{
              mb: 3,
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#5570F1",
                },
              },
            }}
          >
            <InputLabel id="test-select-label">Alert Light</InputLabel>
            <Select
              sx={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={trolleyInformation?.alertLight}
              label="Select AlertLight"
              name="alertLight"
              onChange={handleInputChange}
            >
              <MenuItem value={"nolight"}>No Light</MenuItem>
              <MenuItem value={"green"}>Green</MenuItem>
              <MenuItem value={"red"}>Red</MenuItem>
              <MenuItem value={"orange"}>Orange</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={
              trolleyInformation.qrCode === "" ||
              trolleyInformation.physicalWeight === "" ||
              trolleyInformation.virtualCartWeight === ""
            }
            sx={{
              backgroundColor: "#5570F1",
              color: "white",
              fontWeight: 600,
              padding: "12px 24px",
              borderRadius: "8px",
              fontSize: "1rem",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#405ABD",
              },
              mt: 2,
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TrolleyModal;
