import { useNavigate } from "react-router-dom";
import "./style.css";

const FeedbackSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="icon-container">
        <span className="checkmark">✔</span>
      </div>
      <div className="title">
        <h1>FeedBack Submitted!</h1>
      </div>
      <div className="message">
        <p>Your Valueable Feedback Added Successfully!</p>
      </div>
    </div>
  );
};

export default FeedbackSuccess;
