import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import { useQuickCommerce } from "../context/QuickCommerceProvider";
import ProductCard from "./layout/ProductCard"; // Updated to ProductCard
import SidebarItem from "./layout/SideBarCard";

const SubcategoryPage = () => {
  const { categoryId } = useParams(); // Assuming you pass category ID in the URL
  const location = useLocation();
  const navigate = useNavigate();
  const { catid, categoryName } = location.state;
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Track selected subcategory
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const { quickCommerce } = useQuickCommerce();

  //modal states

  const fetchSubcategories = async () => {
    try {
      const result = await axios.get(
        `${server}/public/subcategory/find/${catid}`,
      );
      console.log("result is ", result.data);
      setSubcategories(result.data || []); // Ensure it's an array

      const response = await axios.get(
        `${server}/public/products/bySubCategoryId/${result.data[0]._id}/productType=${quickCommerce}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log("resp", response.data);
      setProducts(response.data || []); // Ensure it's an array
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProducts = async (subcategoryId) => {
    try {
      const result = await axios.get(
        `${server}/public/products/bySubCategoryId/${subcategoryId}?productType=${quickCommerce}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log(result.data);
      setProducts(result.data.filter((item) => item.published == true));
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch subcategories and products based on categoryId
  useEffect(() => {
    fetchSubcategories();
  }, [categoryId]);
  useEffect(() => {
    fetchProducts(selectedSubcategory);
  }, [selectedSubcategory]);
  console.log("sub", subcategories);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <Typography variant="h6" sx={CategoryTitle}>
          {categoryName}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", padding: 0, border: "1px solid #E8E8E8" }}>
        {/* Sidebar for subcategories */}
        {/* <Box
          sx={{
            width: "20%", // Adjust width
            borderRight: "1px solid #ddd",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <List>
            {subcategories.map((subcategory) => (
              <ListItem
                button
                key={subcategory._id}
                onClick={() => setSelectedSubcategory(subcategory._id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border:
                    selectedSubcategory === subcategory._id
                      ? "1px solid #1976d2"
                      : "none",
                  borderRadius: 1,
                  mb: 1,
                  padding: 0.5,
                  backgroundColor:
                    selectedSubcategory === subcategory._id
                      ? "#e3f2fd"
                      : "transparent",
                }}
              >
                <Box
                  component="img"
                  src={subcategory.imageUrl}
                  alt={subcategory.name}
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 1,
                    objectFit: "cover",
                    marginBottom: 0.5,
                  }}
                />
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    fontSize: "0.875rem",
                    lineHeight: 1.2,
                  }}
                >
                  Eggs and Mik with sugar
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box> */}
        <Box sx={{ width: "20% ", padding: "8px", overflowY: "auto" }}>
          {subcategories.map((subcategory) => (
            <SidebarItem
              key={subcategory._id}
              subcategory={subcategory}
              isSelected={selectedSubcategory === subcategory._id}
              onClick={() => setSelectedSubcategory(subcategory._id)} // Update selected category
            />
          ))}
        </Box>
        {/* <Sidebar /> */}

        {/* Main area for product cards */}
        <Box sx={{ width: "80%", padding: 2 }}>
          <Grid container spacing={2}>
            {products.map((product) => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={product.id}>
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {/* <SizeSelectionModal
        open={open}
        handleClose={handleClose}
        productDetails={products}
        setCount={setCount}
      /> */}
    </>
  );
};

export default SubcategoryPage;

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "20px",
  padding: "10px 20px",
};
