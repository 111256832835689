import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as ShoppingBag } from "../../../assets/ShoppingBag.svg";

const MainDivOrder = {
  width: "100%",
  marginBottom: "100px",
};

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "15px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Poppins",
};

const OrderDoneText = {
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "Poppins",
  textAlign: "center",
  padding: "0px 50px",
  color: "#181725",
  margin: "10px 0px",
};

const OrderPlacedText = {
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "Poppins",
  textAlign: "center",
  padding: "0px 50px",
  color: "#7C7C7C",
  margin: "10px 0px",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "500",
  width: "100%",
  borderRadius: "8px",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
};

const ShoppingCart = () => {
  return (
    <Box sx={MainDivOrder}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Shopping Cart
        </Typography>
      </Box>
      <ShoppingBag className="done-order-icon" />
      <Typography sx={OrderDoneText}>Your cart is empty !</Typography>
      <Typography sx={OrderPlacedText}>
        You will get a response within a few minutes.
      </Typography>

      <Box
        sx={{
          padding: "0px 10px",
          position: "absolute",
          width: "100%",
          bottom: "10px",
        }}
      >
        <Link to="/home">
          <Button sx={ButtonCart}>Start Shopping</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ShoppingCart;
