import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import server from "../../server";
import ProductNotFound from "../Search/Layout/ProductNotFound";
import SubCategoryCard from "./Layout/SubCategoryCard";

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "20px",
  padding: "10px 20px",
};

const Productcard = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  overflow: "hidden",
};

const SubCategory = () => {
  // const { catid } = useParams();
  const location = useLocation();
  const { catid } = location.state || {};

  const [subcategories, setSubCategories] = useState([]);
  const [catname, setname] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //this actually fetches all sub cat under the given catid
  const fetchAllCategories = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${server}/public/sequence/SubCategory?parent=${catid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSubCategories(result.data);

      // const result = await axios.get(
      //   `${server}/public/subcategory/find/${catid}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     },
      //   },
      // );
      // setSubCategories(result.data);
    } catch (err) {}

    try {
      const getcatname = await axios.get(`${server}/public/category/${catid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setname(getcatname.data.name);
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    // if (!localStorage.getItem("user")) navigate("/");
    // else fetchAllCategories();

    fetchAllCategories();
  }, []);

  // useEffect(() => {}, [subcategories]);
  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            // navigate(-1)
            navigate("/category");
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <Typography variant="h6" sx={CategoryTitle}>
          {catname}
        </Typography>
      </Box>

      <Box sx={Productcard}>
        <Grid container spacing={3} sx={{ padding: "10px 20px" }}>
          {subcategories.length === 0 ? (
            <Box sx={{ position: "absolute", top: "25%" }}>
              {" "}
              <ProductNotFound
                message={"No SubCategories to Display"}
                loading={loading}
              />
            </Box>
          ) : (
            // subcategories &&
            subcategories
              .filter(({ published }) => published)
              .map((currsubcategory, i) => {
                return currsubcategory.imageUrl != "noimage" ? (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    key={currsubcategory._id}
                  >
                    {/* //subcat name sub cat id and cat id */}
                    <Link
                      // to={`/categoryproducts/${currsubcategory.name}/${currsubcategory._id}/${currsubcategory.categoryId}`}
                      to={`/categoryproducts/${catname}/${currsubcategory.name}`}
                      state={{
                        subcategoryId: currsubcategory._id,
                        categoryId: currsubcategory.categoryId,
                        name: currsubcategory.name,
                      }}
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <SubCategoryCard
                        name={currsubcategory.name}
                        imgsrc={currsubcategory.imageUrl}
                      />
                    </Link>
                  </Grid>
                ) : null;
              })
          )}{" "}
        </Grid>
      </Box>
    </Box>

    //deleted static grids
  );
};

export default SubCategory;
