import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Bag } from "../../../../assets/cart.svg";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import { weightvariants } from "../../../../assets/variants";
import { topRightToastStyles } from "../../../../constants/styles";
import { fetchData } from "../../../../redux/slices/dataSlice";
import server from "../../../server";
import { NavBarContext } from "../../Navbar/Navbar";
import CategoryProductCardShimmer from "../../Shimmers/CategoryProductCardShimmer";

const mainCardDiv = {
  display: "flex",
  border: "2px solid #F0F0F0",
  // margin: "2px 0",
  padding: "12px 10px",
  width: "100%",
  // minHeight: "20vh",
  // overflow: "hidden"
  height: "fit-content",
  justifyContent: "space-between",
};

// const InventoryInput = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     color: "#000000",
//     marginTop: "10px",
//   },
//   "& .MuiInputBase-input": {
//     color: "#808080",
//     backgroundColor: "#ffffff",
//     borderRadius: "3px",
//     border: "1px solid #E0E0E0",
//     fontSize: "12px",
//     padding: "0px 5px",
//   },
// }));

const buttonPlusMinus = {
  padding: "8px 18px",
  color: "#fff",
};

const cardTitle = {
  color: "#37474F",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
  display: "flex",
  flexWrap: "wrap",
  height: "50px",
  // maxWidth: "40vw",
  textOverflow: "ellipsis",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "14px",
  fontFamily: "Poppins",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "20px",
  fontFamily: "Poppins",
};

const buttonProductCard = {
  color: "#fff",
  backgroundColor: "#5EC401",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 25px",
  // margin: "0px 0px",
  textTransform: "capitalize",
  borderRadius: "7px",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  "&.Mui-disabled": {
    // background: "#eaeaea",
    color: "#fff",
  },
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "30px",
  position: "absolute",
  padding: "4px",
  fontSize: "14px",
  top: "0",
  left: "0",
  height: "fit-content",
};

const favoriteIcon = { color: "red", minWidth: "8px" };

const CategoryProductCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useContext(NavBarContext);
  // const { setItems, wishList, fetchWishList } = useContext(NavBarContext);
  const {
    setItems = () => {},
    wishList = [],
    fetchWishList = () => {},
  } = useContext(NavBarContext) ?? {};

  const [productDetails, setProductDetails] = useState();
  const [variants, setVariants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(1);
  //qtybutton is just to know if we need to show add to cart btn or plusminus button
  const [quantitybutton, setQuantityButton] = useState(false);
  //quantity state stores quantity of item
  const [quantity, setQuantity] = useState();

  const [wishlistdetails, setwishlistdetails] = useState([]);
  // const [wishstatus, setwish] = useState(false);

  const [weights, setWeights] = useState(weightvariants.weights);

  //modal states
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [disabled, setDisabled] = useState(true);

  const [wishstatus, setWishStatus] = useState(wishList.includes(props.prodid));

  useEffect(() => {
    setWishStatus(wishList.includes(props.prodid));
  }, [wishList]);

  const fetchproductDetails = async () => {
    //get the product and its variants with the product id

    // const result = await axios.get(`${server}/products/${props.prodid}`, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // });
    // setProductDetails(result.data.product);

    if (localStorage.getItem("cart")) {
      //check for prexisting product
      // console.log("checking cart")
      let cartarr = JSON.parse(localStorage.getItem("cart"));
      const exists = cartarr?.some(function (element) {
        if (element[0] == props.prodid) {
          //variant or product
          if (element.length === 3) {
            if (parseInt(element[2]) > 0) {
              setQuantity(parseInt(element[2]));
              // console.log("quantity grtr than 0 for prod id " + element[0])
              setQuantityButton(true);
              setSelectedVariant(element[1].toString());
            } else {
              // console.log("set false for " + element[0])
              setQuantityButton(false);
            }
          } else {
            if (parseInt(element[1]) > 0) {
              setQuantity(parseInt(element[1]));
              setQuantityButton(true);
            }
          }
          // console.log(props.prodid + " " + element[1])
          return true;
        }
      });

      if (!exists) {
        setQuantityButton(false);
      }
    }
  };

  const addtocart = async (e) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
    e.preventDefault();
    let arr;
    // handleOpen()
    //using JSON of weights as given on 22 july
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr?.some(function (element) {
        if (element[0] == props.prodid) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            element[1] = parseInt(selectedVariant);
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.productDetails.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.productDetails.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setQuantity(1);
          setItems((current) => current + 1);
          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (props.productDetails?.subCategoryId?.name?.includes("Loose")) {
          temparr = [props.prodid, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.productDetails.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.productDetails.price * selectedVariant,
            );
        } else {
          temparr = [props.prodid, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.productDetails.price,
          );
        }
        cartarr?.push(temparr);
        setQuantity(1);
        setQuantityButton(true);
        setItems((current) => current + 1);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));
    dispatch(fetchData())
      .unwrap()
      .then((data) => {
        console.log("Data fetched successfully:", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    //latest code ends here from 22 july

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // }
  };

  const displaybutton = () => {
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    setQuantityButton(false);

    //22 july code below
    cartarr?.some(function (element) {
      if (element[0] == props.prodid) {
        // console.log('changing button' + element[1])
        if (element.length === 3) {
          if (parseInt(element[2]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[2]));
            return true;
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            // console.log("display called " + props.prodid);

            setQuantityButton(true);
            setQuantity(parseInt(element[1]));
            return true;
          }
        }
      }
      // else setQuantityButton(false)
    });
  };

  const getwishes = async () => {
    // console.log(localStorage.getItem('user'))
    const result = await axios.get(
      `${server}/user/wishlist/status/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
    if (result.data.length !== 0) {
      setwishlistdetails(result.data[0].productList);
      // result.data[0].productList.forEach((product) => {
      //   if (product == props.prodid) {
      //     // console.log('in wishlist')
      //     setwish(true);
      //   }
      // });
    }
  };

  useEffect(() => {
    // getwishes();
    displaybutton();
    // console.log(props);
  }, []);

  useEffect(() => {
    // fetchproductDetails();
    // displaybutton()
  }, [props.prodid]);

  useEffect(() => {
    displaybutton();
    // console.log(productDetails)
  }, [props.productDetails]);

  useEffect(() => {
    setDisabled(false);
  }, [selectedVariant]);

  useEffect(() => {
    // console.log({ "productid": props.prodid, "properties": properties })
  }, [variants, properties]);

  useEffect(() => {
    fetchproductDetails();
  }, []);

  const handleChange = (event, i) => {
    event.preventDefault();
    // setSelectedVariant(event.target.value);
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    const exists = cartarr?.some(function (element) {
      if (element[0] == props.prodid) {
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              props.productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              props.productDetails.price * element[1] * element[2],
          );

        element[1] = event.target.value;

        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.productDetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.productDetails.price * element[1] * element[2],
          );

        return true;
      }

      // else setQuantityButton(false)
    });

    localStorage.setItem("cart", JSON.stringify(cartarr));

    setSelectedVariant(event.target.value);
  };

  const changestatus = async (e) => {
    e.preventDefault();
    if (wishstatus) {
      //call delete wishlist axios call
      const result = await axios.post(
        `${server}/user/wishlist/delete`,
        {
          userId: localStorage.getItem("user"),
          productId: props.prodid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(result)
    } else {
      //call add wishlist axios call
      const result = await axios.post(
        `${server}/user/wishlist/add`,
        {
          userId: localStorage.getItem("user"),
          productId: props.prodid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      toast.success("Added To WishList", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // console.log(result)
    }
    // setwish(!wishstatus);
    fetchWishList();
  };

  const changequantity = (e, i) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
    e.preventDefault();
    // console.log('quant change')
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below

    if (i == 1) {
      //plus button
      // props.proddetail[1] = 6
      if (
        props.productDetails.buyinglimit === quantity &&
        props.productDetails.buyinglimit !== 0
      ) {
        toast.warning("Buying Limit Exceeded", topRightToastStyles);
        return;
      }

      setQuantity((current) => current + 1);
      //change in localstorage also

      //check if variant or product FIRST then proceed
      //it is a product not a variant
      const exists = cartarr?.some(function (element) {
        if (element[0] == props.prodid) {
          // console.log("found prod for increase")
          if (props.productDetails?.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.productDetails.price * selectedVariant,
              );
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.productDetails.price,
            );
            element[1] = (parseInt(element[1]) + 1).toString();
          }

          return true;
        }
      });

      // toast.success("Quantity Increased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      setQuantity((current) => current - 1);

      //it is a product not a variant
      const exists = cartarr?.some(function (element) {
        if (element[0] == props.prodid) {
          if (props.productDetails?.subCategoryId?.name?.includes("Loose")) {
            element[2] = (parseInt(element[2]) - 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  props.productDetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  props.productDetails.price * selectedVariant,
              );
            if (element[2] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
          } else {
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) -
                props.productDetails.price,
            );
            element[1] = (parseInt(element[1]) - 1).toString();
            if (element[1] == 0) {
              //remove from cart
              setQuantityButton(false);
              setItems((current) => current - 1);
              return false;
            }
          }
          return true;
        }
      });

      // toast.success("Quantity Decreased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
    localStorage.setItem("cart", JSON.stringify(cartarr));
  };

  return (
    //implementing with json variants below
    <Box loading="lazy">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            // top: '30vh',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            width: "100vw",
            // height: "70vh",
            bgcolor: "background.paper",
            // borderRadius: "5%",
            borderTopLeftRadius: "5%",
            borderTopRightRadius: "5%",
            boxShadow: 24,
            // padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "quicksand",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Choose an available size
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ overflow: "scroll" }}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => handleChange(e)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    overflow: "scroll",
                  }}
                >
                  {weights &&
                    weights.map((currweight, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "40%",
                            border: "1px solid #EAEAEA",
                            padding: "5px",
                            marginBottom: "5px",
                            // maxHeight:"10vh"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // gap: 10,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "quicksand",
                                fontWeight: 600,
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {currweight >= 100 ? (
                                <>
                                  {currweight}
                                  gm
                                </>
                              ) : (
                                <>
                                  {currweight}
                                  kg
                                </>
                              )}{" "}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "quicksand",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "rgba(243, 122, 32, 1)",
                                }}
                              >
                                ₹{" "}
                                {currweight >= 100 ? (
                                  <>
                                    {" "}
                                    {(
                                      props.productDetails?.price *
                                      (currweight / 1000)
                                    ).toFixed(2)}{" "}
                                  </>
                                ) : (
                                  <>
                                    {props.productDetails?.price * currweight}
                                  </>
                                )}
                              </Typography>
                              <FormControlLabel
                                value={currweight}
                                control={<Radio />}
                                sx={{
                                  margin: "0px",
                                  padding: "5px",
                                }}
                              />
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                  <Box sx={{ width: "40%" }}></Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Button
              sx={buttonProductCard}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleClose();
                addtocart(e);
              }}
            >
              <Box></Box>
              <Typography> Add to Bag</Typography>

              <Bag
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "14px",
                  height: "14px",
                  margin: "-3px 5px 0px 0px",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Modal>

      {!props.productDetails ? (
        <CategoryProductCardShimmer />
      ) : (
        <Box sx={mainCardDiv} onClick={(e) => e.preventDefault()}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "25%", // Adjust as needed
              height: "25vw", // Ensure square shape
              padding: "8px",
            }}
            onClick={() => {
              navigate(`/productdetail/${props?.productDetails?.name}`, {
                state: {
                  prodid: props.prodid,
                },
              });
            }}
          >
            <img
              src={
                props.productDetails.imageUrl === "noimage"
                  ? DefaultImage
                  : props.productDetails.imageUrl
              }
              style={{
                width: "30vw", // Fill container
                height: "100%", // Maintain aspect ratio
                objectFit: "contain", // Ensure the image covers the container without distortion
              }}
              onError={(e) => {
                e.target.src = DefaultImage;
              }}
            />
            {parseInt(
              props.productDetails.mrpPrice - props.productDetails.price,
              10,
            ) > 0 && (
              <Typography sx={salePercentage}>
                Save ₹
                {parseInt(
                  props.productDetails.mrpPrice - props.productDetails.price,
                  10,
                )}
                <br />
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              // flex: "50",
              padding: "0 6px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "8px",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              sx={cardTitle}
              onClick={() => {
                navigate(`/productdetail/${props?.productDetails.name}`, {
                  state: {
                    prodid: props.prodid,
                  },
                });
              }}
            >
              {props.productDetails?.name}
            </Typography>

            {props.productDetails?.subCategoryId?.name?.includes("Loose") && (
              <FormControl
                size="small"
                sx={{ width: "100%", margin: "10px 0px" }} // Full width of parent
              >
                <Box
                  sx={{
                    width: "50%",
                    border: "1px solid #EAEAEA",
                    padding: "6px 10px",
                    textAlign: "left",
                  }}
                  onClick={handleOpen}
                >
                  {/* {selectedVariant >= 100 ? (
                    <Typography sx={salePriceText} component={"span"}>
                      ₹
                      {(
                        props.productDetails.price *
                        (selectedVariant / 1000)
                      ).toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography sx={salePriceText} component={"span"}>
                      ₹
                      {(props.productDetails.price * selectedVariant).toFixed(
                        2,
                      )}
                    </Typography>
                  )} */}
                  {selectedVariant >= 100
                    ? `${selectedVariant}gm`
                    : `${selectedVariant}kg`}
                </Box>
              </FormControl>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80",
              }}
            >
              {props.productDetails?.subCategoryId?.name?.includes("Loose") ? (
                <Box
                  size="small"
                  sx={{
                    width: "100%",
                    // border: "1px solid #EAEAEA",
                    padding: "6px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {selectedVariant >= 100 ? (
                    <Typography sx={salePriceText} component={"span"}>
                      ₹
                      {(
                        props.productDetails.price *
                        (selectedVariant / 1000)
                      ).toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography sx={salePriceText} component={"span"}>
                      ₹
                      {(props.productDetails.price * selectedVariant).toFixed(
                        2,
                      )}
                    </Typography>
                  )}
                  {/* {selectedVariant >= 100
                    ? `${selectedVariant}gm`
                    : `${selectedVariant}kg`} */}
                </Box>
              ) : (
                <>
                  <Box sx={{ width: "100%" }}>
                    {props.productDetails.mrpPrice !==
                      props.productDetails.price && (
                      <Typography sx={priceText}>
                        ₹{props.productDetails.mrpPrice?.toFixed(2)}
                      </Typography>
                    )}
                    <Typography sx={salePriceText}>
                      ₹{props.productDetails.price?.toFixed(2)}
                    </Typography>{" "}
                  </Box>
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-betweem",
                  alignItems: "center",
                  width: "60%",
                  gap: "10px",
                }}
              >
                <Button
                  style={{
                    padding: 0,
                    minWidth: "16px",
                  }}
                  onClick={(e) => changestatus(e)}
                >
                  {wishstatus ? (
                    <FavoriteIcon sx={favoriteIcon} />
                  ) : (
                    <FavoriteBorderIcon style={favoriteIcon} />
                  )}
                </Button>

                {quantitybutton ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#5EC401",
                      color: "#fff",
                      borderRadius: "7px",
                    }}
                  >
                    <IconButton
                      sx={buttonPlusMinus}
                      onClick={(e) => changequantity(e, 0)}
                      focusRipple
                    >
                      -
                    </IconButton>
                    {quantity}
                    <IconButton
                      sx={buttonPlusMinus}
                      onClick={(e) => changequantity(e, 1)}
                    >
                      +
                    </IconButton>
                  </Box>
                ) : (
                  <Box>
                    <Button
                      sx={buttonProductCard}
                      onClick={(e) => {
                        if (
                          props.productDetails?.subCategoryId?.name?.includes(
                            "Loose",
                          )
                        ) {
                          handleOpen();
                        } else {
                          addtocart(e);
                        }
                      }}
                      disabled={
                        props.productDetails.stocklimit &&
                        props.productDetails.stocklimit >
                          props.productDetails.stock
                      }
                    >
                      <Bag
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "14px",
                          height: "14px",
                          margin: "-3px 5px 0px 0px",
                        }}
                      />
                      {props.productDetails.stocklimit &&
                      props.productDetails.stocklimit >
                        props.productDetails.stock ? (
                        <>Out of Stock</>
                      ) : (
                        <>Add</>
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CategoryProductCard;
