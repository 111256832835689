import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Backdrop, FormControl, InputLabel, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import { alpha, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";
import ImageUpload from "../../Inventory/Layout/ImageUpload";
import Loader from "../../Loader/loader";
import CategoryForm from "./CategoryForm";
import SubCategoryTable from "./SubCategoryTable";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "categoryid",
    numeric: false,
    disablePadding: true,
    label: "SubCategory Id",
  },
  {
    id: "categoryname",
    numeric: false,
    disablePadding: true,
    label: "SubCategory Name",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created Date/Time",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{
            minWidth: "10vw",
            backgroundColor: "#5570F1",
            color: "white",
          }}
          defaultValue={0}
          label="Select Category"
          onChange={(e) => {
            props.setselectedCategory(e.target.value);
          }}
        >
          {props.categories &&
            props.categories.map((element, i) => {
              return <MenuItem value={i}>{element.name}</MenuItem>;
            })}
        </Select>
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const SubCategoryList = (props) => {
  // console.log(props.rows);
  //rows are basically your data
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [active_users, setActive_users] = useState(0)
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [selectedCategory, setselectedCategory] = useState(0);
  const [orirows, setorirows] = useState([]);
  const [sequenceSubCategories, setSequenceSubCategories] = useState([]);

  //edit and add category  modal states
  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState(false);

  const [oldsubcategory, setoldsubcategory] = useState();
  const [newsubcategoryname, setnewsubcategoryname] = useState();
  const [newsubcategorycatid, setnewsubcategorycatid] = useState();
  const [imageFile, setImageFile] = useState();
  const [selectedcat, setselectedcat] = useState();
  const [value, setValue] = useState(0);
  const [categoryFormOpen, setCategoryFormOpen] = useState();
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [sequenceLoading, setSequenceLoading] = useState(false);

  const handleOpenDialog = () => {
    setCategoryFormOpen(true);
    // Fetch and set categories data from API
  };

  const handleCloseDialog = () => {
    setCategoryFormOpen(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setedit(false);
    setoldsubcategory();
    setImageFile();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories?.length) : 0;

  const getSubCategories = async () => {
    const result = await axios.get(
      `${server}/subcategory/find/${categories[selectedCategory]._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    setSubCategories(result.data);
    try {
      const response = await axios.get(
        `${server}/sequence/SubCategory?parent=${categories[selectedCategory]._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      console.log("response is", response.data);
      setSequenceSubCategories(response.data || []);
    } catch (error) {
      console.error("Failed to fetch sequence subcategories:", error);
      setSequenceSubCategories([]);
    }
  };

  const getCategories = async () => {
    try {
      // console.log("called")
      const result = await axios.get(`${server}/category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(result.data)
      setCategories(result.data);
      setorirows(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CreateSubCategory = async () => {
    const result = await axios.post(
      `${server}/admin/subcategory/create`,
      {
        name: newsubcategoryname,
        imageUrl: imageFile,
        categoryId: categories[newsubcategorycatid]._id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    console.log(result);
    setImageFile();
    handleClose();
    getCategories();
  };

  const UpdateSubCategory = async (id) => {
    const result = await axios.put(
      `${server}/admin/subcategory/update/${id}`,
      {
        name: newsubcategoryname ? newsubcategoryname : oldsubcategory.name,
        categoryId: categories[newsubcategorycatid]?._id
          ? categories[newsubcategorycatid]
          : oldsubcategory.categoryId,
        imageUrl: imageFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
    setImageFile();
    setselectedcat();
    setnewsubcategoryname();
    handleClose();
    setnewsubcategorycatid();
    getCategories();
  };

  const handleStatus = async (value, id) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    setLoading(true);

    try {
      const updatestatus = await axios.put(
        `${server}/admin/subcategory/update/${id}`,
        {
          published: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      // functionchange()
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    // fetchproducts();
    // setStatus({ ...status, [key]: value })
  };

  useEffect(() => {
    // console.log("starting useeffect")
    getCategories();
  }, []);

  useEffect(() => {
    if (categories.length > 0) getSubCategories();
  }, [categories, selectedCategory]);

  useEffect(() => {}, [orirows, selectedcat, edit, open]);

  useEffect(() => {
    if (edit) {
      categories.map((element, i) => {
        if (element._id === oldsubcategory.categoryId) {
          setselectedcat(i);
          console.log("cat old is id " + i);
        }
        if (i === categories.length - 1) {
          console.log("opened" + selectedcat);
          handleOpen();
        }
      });
    }
  }, [oldsubcategory]);

  const updateSubCategoriesSquence = async () => {
    try {
      setSequenceLoading(true);
      if (selectedSubCategories.length > 0) {
        const result = await axios.patch(
          `${server}/sequence/add-to-sequence?parent=${categories[selectedCategory]._id}&parentCollectionName=Category`,
          {
            collectionName: "SubCategory",
            parent: categories[selectedCategory]._id,
            ids: selectedSubCategories,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        getSubCategories();
        setSequenceLoading(false);
      }
    } catch (error) {
      // Handle different types of errors
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        toast.error("No response from server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
        toast.error(`Request error: ${error.message}`);
      }
      setSequenceLoading(false);
    }
  };

  const handleSelectSubCategories = (selected) => {
    // Create a new array with only the _id values from the selected array
    const selectedIds = selected.map((category) => category._id);
    console.log("map ", selectedIds);
    // Set the state with the new array
    setSelectedSubCategories(selectedIds);
    console.log("selected ", selectedIds);
  };

  useEffect(() => {
    if (selectedSubCategories.length > 0) {
      updateSubCategoriesSquence();
    }
  }, [selectedSubCategories]);

  useEffect(() => {
    if (oldsubcategory?.name) {
      setnewsubcategoryname(oldsubcategory.name);
    }
  }, [oldsubcategory]);

  return (
    <>
      <Backdrop sx={{ color: "white", zIndex: "1201" }} open={sequenceLoading}>
        <Loader />
      </Backdrop>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            // height: "80vh"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDIrection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {" "}
              {edit ? <> Edit SubCategory</> : <> Create New SubCategory</>}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <ImageUpload
                newProduct={[]}
                // setNewProduct={}
                setImageFile={setImageFile}
                imagesSave={typeof imageFile === "string" ? imageFile : ""}
                isEdit={false}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
                height: "100%",
              }}
            >
              <TextField
                // value={ newproductprice}
                // className="product-input1"
                label="SubCategory Name"
                // defaultValue={oldsubcategory && oldsubcategory.name}
                value={oldsubcategory && oldsubcategory.name}
                // variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#EFF1F9",
                  marginBottom: "10px",
                }}
                disabled
                // placeholder="Product MRP"
                // onChange={(e) => {
                //   setnewsubcategoryname(e.target.value);
                // }}
              />
              <FormControl
                variant="outlined"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
                margin={"1"}
              >
                {!edit ? (
                  <InputLabel id="test-select-label">Category</InputLabel>
                ) : (
                  <></>
                )}
                {categories ? (
                  <Select
                    sx={{
                      width: "100%",
                      marginTop: "5px",
                      marginLeft: "5px",
                      backgroundColor: "#EFF1F9",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={}
                    defaultValue={
                      selectedcat || selectedcat === 0 ? selectedcat : null
                    }
                    label="Select Category"
                    onChange={(e) => {
                      setnewsubcategorycatid(e.target.value);
                    }}
                    disabled
                  >
                    {categories &&
                      categories.map((element, i) => {
                        return <MenuItem value={i}>{element.name}</MenuItem>;
                      })}
                  </Select>
                ) : (
                  <></>
                )}
              </FormControl>
              <Button
                sx={{
                  backgroundColor: "#5570F1",
                  color: "white",
                  fontWeight: "500",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (edit) UpdateSubCategory(oldsubcategory._id);
                  else CreateSubCategory();
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#5570F1",
              color: "#FFFFFF",
              fontWeight: 500,
              fontSize: "20px",
              // padding: "10px",
              paddingLeft: "40px",
              paddingRight: "40px",
              borderRadius: "15px",
              //   marginBottom: "10vh",
              "&:hover": {
                //you want this to be the same as the backgroundColor above
                backgroundColor: "#5570F1",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              handleOpen();
              // navigate('/subcategorylist')
            }}
          >
            Add New SubCategory
          </Button>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All SubCategories" {...a11yProps(0)} />
              <Tab label="Selective  SubCategories" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <SubCategoryTable
              EnhancedTableHead={EnhancedTableHead}
              subcategories={subcategories}
              setSubCategories={setSubCategories}
              categories={categories}
              setselectedCategory={setselectedCategory}
              selected={selected}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              dense={dense}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleStatus={handleStatus}
              setImageFile={setImageFile}
              setoldsubcategory={setoldsubcategory}
              setedit={setedit}
              EnhancedTableToolbar={EnhancedTableToolbar}
              TablePaginationActions={TablePaginationActions}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                  marginBottom: "10px",
                }}
              >
                Add
              </Button>
            </Box>
            <CategoryForm
              open={categoryFormOpen}
              onClose={handleCloseDialog}
              categories={subcategories}
              onSelect={handleSelectSubCategories}
              limit={sequenceSubCategories.length}
              presentData={sequenceSubCategories}
            />

            <SubCategoryTable
              EnhancedTableHead={EnhancedTableHead}
              subcategories={sequenceSubCategories}
              setSubCategories={setSequenceSubCategories}
              categories={categories}
              setselectedCategory={setselectedCategory}
              selected={selected}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              dense={dense}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleStatus={handleStatus}
              setImageFile={setImageFile}
              setoldsubcategory={setoldsubcategory}
              setedit={setedit}
              EnhancedTableToolbar={EnhancedTableToolbar}
              TablePaginationActions={TablePaginationActions}
              isSequenceSubCategory={true}
              getSubCategories={getSubCategories}
              handleOpen={handleOpen}
              selectedCategoryId={categories[selectedCategory]?._id}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default SubCategoryList;
