import { EmailOutlined } from "@mui/icons-material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import server from "../../../server";
const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  color: "#37474F",
};

const CardMainDiv = {
  padding: "20px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #E1E1E1",
  marginTop: "10px",
  "&.MuiBox-root:hover": {
    background: "rgba(94, 196, 1, 0.17)",
    borderRadius: "12px",
  },
};
const locationTitle = {
  fontSize: "18px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const addressText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "500",
  width: "90%",
};
const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #E1E1E1",
  },
  arrowStyle: {
    marginRight: "10px",
  },
  CategoryTitle: {
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  CardMainDiv: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #E1E1E1",
  },
  locationTitle: {
    marginRight: "10px",
    fontSize: "1.2em",
    color: "#181725",
  },
  addressText: {
    fontSize: "1em",
    width: "100%",
  },
};

const tileStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  border: "1px solid #ddd",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  // maxWidth: "300px",
  width: "100%",
};

const linkStyling = {
  textDecoration: "none",
  color: "#181725",
  width: "100%",
  justifyContent: "center",
  display: "flex",
};

const Contact = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getnotifications = async () => {
    const result = await axios.get(
      `${server}/notifications/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setNotifications(result.data.reverse());
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("user")) navigate("/");
  //   else getnotifications();
  // }, []);

  useEffect(() => {
    // console.log(notifications)
  }, [notifications]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.header}>
        <Box sx={styles.arrowStyle}>
          <Link to="/profile">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={styles.CategoryTitle}>
          Contact Us
        </Typography>
      </Box>
      <Box sx={{ padding: "20px 0" }}>
        {/* Click to open:- */}
        <Box sx={{ padding: "0px 10px", borderTop: "solid #E1E1E1" }}>
          <Box sx={styles.CardMainDiv}>
            {/* <Typography sx={styles.locationTitle}>
              <LocationOnOutlinedIcon />
            </Typography> */}
            {/* <Typography sx={styles.addressText}> */}

            <Link
              to="https://www.google.com/maps/dir/?api=1&destination=18.47314%2C73.82151"
              style={{
                textDecoration: "none",
                color: "#181725",
                width: "100%",
              }}
            >
              <iframe
                style={{ width: "100%" }}
                src="https://www.openstreetmap.org/export/embed.html?bbox=73.82003277540208%2C18.472073843698716%2C73.82288664579393%2C18.474149748822263&amp;layer=mapnik"
              ></iframe>
              {/* <div
                // onClick={() =>
                //   (window.location.href =
                //     "https://www.google.com/maps/dir/?api=1&destination=18.47314%2C73.82151")
                // }
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  zIndex: 10,
                }}
              ></div>{" "} */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Typography sx={styles.locationTitle}>
                    <LocationOnOutlinedIcon />
                  </Typography>{" "}
                </div>
                <div>
                  {" "}
                  <br />
                  Urban Bazaar C/O Rajdhani Retail
                  <br />
                  Ground Floor, Parinay Complex
                  <br />
                  Manik Baug, Sinhgad Road
                  <br />
                  Pune 411051
                </div>
              </div>
            </Link>
            {/* </Typography> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "16px",
              textAlign: "center",
              gap: "16px",
            }}
          >
            <Link to="tel:+918378909909" style={linkStyling}>
              <Box sx={tileStyles}>
                <Typography sx={{ fontSize: "32px", marginBottom: "8px" }}>
                  <LocalPhoneOutlined />
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#181725" }}>
                  Customer Service Number:
                  <span style={{ color: "blue" }}>83789-09909</span>
                  {/* <br />
                  Purchase Related Inquiries:{" "}
                  <span style={{ color: "blue" }}>93708-57054</span> */}
                  <br />
                  <a href="https://wa.me/message/DH5NVJHXB4EEA1 ">
                    {" "}
                    Chat with us on{" "}
                    <span style={{ color: "blue" }}> Whatsapp</span>
                  </a>
                </Typography>
              </Box>{" "}
            </Link>

            <Link to="mailto:rajdhaniretail@gmail.com" style={linkStyling}>
              {" "}
              <Box sx={tileStyles}>
                <Typography sx={{ fontSize: "32px", marginBottom: "8px" }}>
                  <EmailOutlined />
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#181725" }}>
                  E-mail:{" "}
                  <span style={{ color: "blue" }}>
                    rajdhaniretail@gmail.com
                  </span>
                </Typography>
              </Box>{" "}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
