import { Navigate } from "react-router-dom";

function isMobileBrowser() {
  console.log(navigator.userAgent, navigator.vendor, window.opera);
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    true ||
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent,
    )
  );
}

const PrivateRoute = ({ children }) => {
  // const navigate = useNavigate()
  // const location = useLocation();
  const isAuthenticated =
    localStorage.getItem("adminuser") !== null ||
    localStorage.getItem("user") !== null;

  return isAuthenticated ? (
    children
  ) : (
    <>
      {console.log("else")}
      <Navigate to="/login" />
    </>
  );
};

export default PrivateRoute;
