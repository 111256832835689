import { Box } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Account } from "../../../assets/account.svg";
import { ReactComponent as Cart } from "../../../assets/cart.svg";
import { ReactComponent as Categories } from "../../../assets/categories.svg";
import { ReactComponent as Home } from "../../../assets/clienthome.svg";

export const NavBarContext = createContext();

const NavbarContainer = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.84)",
  borderRadius: "20px 20px 0px 0px;",
  padding: "16px 0px",
  position: "fixed",
  bottom: "0",
  width: "100%",
  minHeight: "5vh",
  zIndex: 10,
  transition: "transform 0.3s ease-in-out",
};

const NavbarDiv = {
  display: "flex",
  justifyContent: "space-evenly",
};

const NavItemStyle = {
  borderRadius: "50%",
  width: "54px",
  height: "54px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
};

const Navbar = () => {
  const items = useContext(NavBarContext);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const scrollThreshold = 20; // Scroll threshold to hide navbar
  // const [items, setitems] = useState()

  const controlNavbar = () => {
    // If user scrolls down more than the threshold
    if (window.scrollY - lastScrollY > scrollThreshold) {
      setShowNavbar(false);
    }
    // If user scrolls up
    else if (lastScrollY - window.scrollY > scrollThreshold) {
      setShowNavbar(true);
    }

    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      let cartarr = JSON.parse(localStorage.getItem("cart"));
      let num = 0;
      cartarr?.forEach((element) => {
        if (element.length === 3 && parseInt(element[2]) > 0) num++;
        else if (element.length === 2 && parseInt(element[1]) > 0) num++;
      });
      if (num > 0) items.setItems(num);
      else items.setItems(0);
    }
  }, []);

  useEffect(() => {}, [items.items]);

  return (
    <Box
      sx={{
        ...NavbarContainer,
        transform: showNavbar ? "translateY(0)" : "translateY(100%)",
      }}
    >
      <Box sx={NavbarDiv}>
        <NavLink
          to="/home"
          style={{ textDecoration: "none" }}
          className={({ isActive }) =>
            isActive ? "active-navbar-class" : "not-active-navbar-class"
          }
        >
          <Box sx={NavItemStyle} className="nav-items">
            <Home />
          </Box>
        </NavLink>

        <NavLink
          to="/category"
          style={{ textDecoration: "none" }}
          className={({ isActive }) =>
            isActive ? "active-navbar-class" : "not-active-navbar-class"
          }
        >
          <Box sx={NavItemStyle} className="nav-items">
            <Categories />
          </Box>
        </NavLink>

        <NavLink
          to="/cart"
          style={{ textDecoration: "none" }}
          className={({ isActive }) =>
            isActive ? "active-navbar-class" : "not-active-navbar-class"
          }
        >
          <Box sx={NavItemStyle} className="nav-items">
            <Box
              sx={{
                borderRadius: "100%",
                backgroundColor: "#F37A20",
                position: "absolute",
                width: "5vw",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                marginLeft: "25px",
                justifyContent: "center",
              }}
            >
              {items.items > 0 ? <>{items.items}</> : <></>}
            </Box>
            <Cart />
          </Box>
        </NavLink>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none" }}
          className={({ isActive }) =>
            isActive ? "active-navbar-class" : "not-active-navbar-class"
          }
        >
          <Box sx={NavItemStyle} className="nav-items">
            <Account />
          </Box>
        </NavLink>
      </Box>
    </Box>
  );
};

export default Navbar;
