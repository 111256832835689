import { Box, Typography } from "@mui/material";

const SidebarItem = ({ subcategory, isSelected, onClick }) => {
  return (
    <Box
      sx={{
        padding: "8px 1px",
        width: "100%",
        height: "auto",
        display: "grid",
        gridTemplateColumns: "1fr",
        position: "relative",
        cursor: "pointer", // Change cursor on hover
        borderRight: "1px solid #E8E8E8",

        // backgroundColor: "#fff",
      }}
      onClick={onClick} // Handle click event
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "3rem", // Icon size
            height: "3rem", // Icon size
            overflow: "hidden",
            borderRadius: "0.5rem",
            backgroundColor: isSelected ? "grey.200" : "transparent", // Change bg color when selected
            transition: "background-color 0.3s",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "-18px", // Adjust the bottom position
              transition: "height 0.1s ease-in-out",
              width: "3rem",
              height: isSelected ? "4rem" : "3rem",
              overflow: "hidden",
            }}
          >
            <img
              src={subcategory.imageUrl}
              alt={subcategory.name}
              style={{
                width: "100%",
                height: "100%",
                transition: "opacity 0.3s",
                opacity: isSelected ? 1 : 0.7, // Change opacity on selection
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: isSelected ? "bold" : "normal", // Change font weight
            color: isSelected ? "#212121" : "var(--colors-black-900)", // Change text color
            fontSize: "12px",
            textAlign: "center",
            // width: "80%", // Width of the text
            marginTop: "0.5rem", // Margin to separate from image
            // lineHeight: 1.2, // Set line height for better spacing
            whiteSpace: "normal", // Allow text to wrap
            overflow: "hidden",
            // textOverflow: "ellipsis", // Add ellipsis for long text
            display: "-webkit-box", // Use flexbox to manage overflow
            WebkitBoxOrient: "vertical", // Ensure vertical orientation
            WebkitLineClamp: 2, // Limit to 2 lines
            maxHeight: "3em", // Adjust based on your line-height (1.2 * 2)
            // breakWord: true, // Allow breaking words
          }}
        >
          {subcategory.name}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            height: "100%",
            width: "2px", // Adjust width as necessary
            backgroundColor: isSelected ? "#4caf50" : "transparent", // Green line only when selected
            borderTopLeftRadius: "0.25rem", // Rounded corners
            borderBottomLeftRadius: "0.25rem",
          }}
        />
      </Box>
    </Box>
  );
};

// const Sidebar = () => {
//   const [selectedCategoryId, setSelectedCategoryId] = useState(null);

//   const categories = [
//     {
//       id: 1,
//       name: "Milk",
//       imageUrl:
//         "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/app/images/subcategory/cms_images/icon/922_1643384380004.png",
//     },
//     {
//       id: 2,
//       name: "Cheese",
//       imageUrl:
//         "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/app/images/subcategory/cms_images/icon/922_1643384380004.png",
//     },
//     {
//       id: 3,
//       name: "Yogurt",
//       imageUrl:
//         "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/app/images/subcategory/cms_images/icon/yogurt_icon.png",
//     },
//     {
//       id: 4,
//       name: "Butter",
//       imageUrl:
//         "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/app/images/subcategory/cms_images/icon/butter_icon.png",
//     },
//     {
//       id: 5,
//       name: "Eggs",
//       imageUrl:
//         "https://cdn.grofers.com/cdn-cgi/image/f=auto,fit=scale-down,q=70,metadata=none,w=180/app/images/subcategory/cms_images/icon/eggs_icon.png",
//     },
//   ];

//   return (
//     <Box sx={{ width: "20% ", padding: "8px", overflowY: "auto" }}>
//       {categories.map((subcategory) => (
//         <SidebarItem
//           key={subcategory.id}
//           subcategory={subcategory}
//           isSelected={selectedCategoryId === subcategory.id}
//           onClick={() => setSelectedCategoryId(subcategory.id)} // Update selected subcategory
//         />
//       ))}
//     </Box>
//   );
// };

export default SidebarItem;
