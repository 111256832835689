import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../server";
import CartProductCard from "./Layout/CartProductCard";

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../redux/slices/dataSlice";
import ShoppingCart from "../ShoppingCart/ShoppingCart";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "5px 7px",
  fontSize: "16px",
  fontWeight: "500",
  width: "90%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  mx: "5%",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.data.items);
  const [netMrp, setNetMrp] = useState();
  const [yourSaving, setYourSaving] = useState();
  console.log("itesm", items);

  //states
  const [cart, setCart] = useState([]);
  const [cartprice, setCartPrice] = useState(localStorage.getItem("cartprice"));
  // const [productDetailsMap, setProductDetailsMap] = useState({});
  const [productDetailsMap, setProductDetailsMap] = useState(items || {});

  const getcartinfo = async () => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  };

  const fetchProductDetails = async (cart) => {
    const prodData = {};
    for (const cartItem of cart) {
      const result = await axios.get(
        `${server}/public/products/${cartItem[0]}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      prodData[cartItem[0]] = result.data.product;
    }
    setProductDetailsMap(prodData);
  };

  // useEffect(() => {
  //   // setTotalSavings(
  //   //   cart.reduce((accumulator, prod) => {
  //   //     // Calculate the savings for the current product
  //   //     console.log(prod);
  //   //     const saved =
  //   //       (prod.length === 3 && prod[2] > 0) ||
  //   //       (prod.length === 2 && parseInt(prod[1], 10) > 0)
  //   //         ? prod.mrpPrice - prod.price
  //   //         : 0;
  //   //     // Accumulate the savings
  //   //     return accumulator + saved;
  //   //   }, 0),
  //   // );
  //   fetchProductDetails(cart);
  // }, [cart]);

  useEffect(() => {
    if (items?.length === 0 || !items || Object.keys(items).length === 0) {
      // console.log("fetch data ", fetchData());
      // dispatch(fetchData());
      dispatch(fetchData())
        .unwrap()
        .then((data) => {
          console.log("Data fetched successfully:", data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      // const data = async () => {
      //   const cart = JSON.parse(localStorage.getItem("cart"));
      //   const productData = await fetchProductDetails(cart);
      //   console.log("product", productData);
      //   dispatch(productData);
      // };
      // data();
    }
  }, [cart]);

  useEffect(() => {
    setProductDetailsMap(items);
  }, [items]);
  const handleFlow = async () => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }

    const addressresult = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // let addressresult;

    // try {
    //   addressresult = await axios.get(
    //     `${server}/address/user/${localStorage.getItem("user")}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //       },
    //     },
    //   );
    // } catch (error) {
    //   console.log(error);
    // }

    // console.log(addressresult.data)

    //check for validity of order minimum rs 500
    if (cartprice < 100) {
      toast.warning("Cannot place orders below ₹100", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (addressresult?.data?.length === 0) {
      //go to add address page with status true
      navigate("/addaddress", { state: { status: "true" } });
    } else {
      navigate("/myaddress", { state: { status: "true" } });
    }
  };

  const getProductById = async (productId) => {
    const product = await axios.get(
      `${server}/public/product/admin/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    return product?.data[0];
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item[0] !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const calculateNetMRP = async () => {
    let netMRP = 0;
    const cart = JSON.parse(localStorage.getItem("cart"));
    const productPromises = cart.map(async (item) => {
      const [productId, quantity] = item;
      const product = await getProductById(productId);
      if (product) {
        return product.mrpPrice * quantity;
      }
      return 0;
    });
    const productMRPs = await Promise.all(productPromises);
    netMRP = productMRPs.reduce((total, mrp) => total + mrp, 0);
    return netMRP;
  };

  useEffect(() => {
    // if (!localStorage.getItem("user")) navigate("/");
    // else getcartinfo();
    getcartinfo();
  }, []);

  useEffect(() => {
    const fetchAndCalculateMRP = async () => {
      const netMrpValue = await calculateNetMRP();
      setYourSaving(netMrpValue - cartprice);
      setNetMrp(netMrpValue);
    };

    fetchAndCalculateMRP();
  }, [cartprice]);

  return (
    <>
      <ToastContainer />
      {JSON.parse(localStorage.getItem("cartprice")) === 0 ? (
        <ShoppingCart />
      ) : (
        <Box sx={{ marginBottom: "100px" }}>
          <Box sx={header}>
            <Box sx={arrowStyle}>
              <Link to="/">
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            </Box>
            <Typography variant="h6" sx={CategoryTitle}>
              Cart
            </Typography>
          </Box>

          {cart &&
            Object.keys(productDetailsMap).length != 0 &&
            cart.map((prod, i) => {
              return (prod.length === 3 && parseInt(prod[2]) > 0) ||
                (prod.length == 2 && parseInt(prod[1]) > 0) ? (
                <CartProductCard
                  proddetail={prod}
                  setCartPrice={setCartPrice}
                  key={i}
                  productDetails={productDetailsMap[prod[0]]}
                  removeFromCart={removeFromCart}
                />
              ) : null;
            })}

          <Box
            sx={{
              position: "fixed",
              bottom: 5,
              right: 2,
              width: "70%",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                bottom: 1,
                left: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "10px 20px",
                color: "green",
                backgroundColor: "white",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                Your Saving
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  color: "green",
                }}
              >
                ₹{yourSaving}
              </Typography>
            </Box>

            <Button
              sx={ButtonCart}
              onClick={(e) => {
                handleFlow();
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                ₹{cartprice}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  color: "inherit",
                }}
              >
                Total
              </Typography>
              <ArrowForwardRoundedIcon
                sx={{
                  position: "absolute",
                  right: "20px",
                }}
              />
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Cart;
