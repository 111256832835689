import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import server from "../../../server";
import CategoryCard from "./CategoryCard";

const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // lineHeight: 1,
  //   boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
};

const ViewCategoryGrid = {
  padding: "1vh 10px",
};

const ViewCategoryGridItem = {
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
};
const ViewAllLink = { textDecoration: "none", color: "black" };

const ViewCategory = ({
  catid,
  toDisplay,
  bannerImg,
  index,
  toLoad,
  setToLoad,
}) => {
  //states
  const [categories, setCategories] = useState([]);
  const [catname, setcatname] = useState("");
  const [mainIndex, setMainIndex] = useState(0);
  const [subCategoriesBySequence, SetSubCategoriesBySequence] = useState([]);

  const [skipCurrent, setSkip] = useState(false);

  const loadNext = () => {
    console.log(`skipping ${catid}`);
    !toLoad.includes(index + 1) && setToLoad([...toLoad, index + 1]);
    // setTimeout(() => setToLoad([...toLoad, index + 1]), 900);
    setSkip(true);
  };

  const getCategoryData = async () => {
    // console.log(`fetch fired ${catid}`);
    if (catid) {
      //get all sub cat using catid and store in categories state
      const subcats = await axios.get(
        `${server}/public/subcategory/find/${catid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(subcats.data);
      setCategories(subcats.data);

      if (!Boolean(subcats.data.length)) loadNext();

      const catinfo = await axios.get(`${server}/public/category/${catid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setcatname(catinfo.data.name);
    } else {
      const result = await axios.get(`${server}/public/category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setCategories(result.data);

      if (!Boolean(result.data.length)) loadNext();
    }
  };

  const startLoad = () => {
    getCategoryData();
    getSubCategoriesBySequence();
    // !toLoad.includes(index + 1) &&
    //   setTimeout(() => setToLoad([...toLoad, index + 1]), 2500);
  };

  const getSubCategoriesBySequence = async () => {
    try {
      const result = await axios.get(
        `${server}/public/sequence/SubCategory?parent=${catid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      SetSubCategoriesBySequence(result.data);
    } catch (error) {
      console.log(error);
      SetSubCategoriesBySequence([]);
    }
  };

  useEffect(() => {
    toLoad.includes(index) &&
      !Boolean(subCategoriesBySequence.length) &&
      startLoad();
  }, [toLoad]);

  useEffect(() => {
    toDisplay &&
      subCategoriesBySequence.map((cat, index) => {
        if (cat._id == toDisplay.main) {
          setMainIndex(index);
          return;
        }
      });
  }, [subCategoriesBySequence]);

  const [displayItems, setDisplayItems] = useState(false);
  useEffect(() => {
    setDisplayItems(toDisplay ? toDisplay.length != 0 : false);
  }, [toDisplay]);

  const [loadCount, setLoadCount] = useState(0);

  useEffect(() => {
    // console.log(
    //   "loadCount",
    //   catid,
    //   categories.filter(
    //     (currcategory) =>
    //       currcategory.imageUrl != "noimage" &&
    //       toDisplay.below.includes(currcategory._id),
    //   ).length,
    //   loadCount,
    // );
    // loadCount != 0 &&
    // console.log("load count is ", loadCount);
    // console.log(
    //   "load sequnce ",
    //   subCategoriesBySequence.filter(
    //     (currcategory) => currcategory.imageUrl != "noimage",
    //   ).length,
    // );
    // console.log("lod toLoad ", toLoad);
    // console.log("index i s", index);
    // loadCount == subCategoriesBySequence.filter(
    //     (currcategory) => currcategory.imageUrl != "noimage",
    //   ).length &&
    //   !toLoad.includes(index + 1) &&
    //   setToLoad([...toLoad, index + 1]);
    //   setTimeout(() => setToLoad([...toLoad, index + 1]), 2500);
    !toLoad.includes(index + 1) && setToLoad([...toLoad, index + 1]);
  }, [loadCount, subCategoriesBySequence, toDisplay]);

  //   const updateLoadCount = () => setLoadCount(setLoadCount((prev) => prev + 1));

  // useEffect(() => {
  //   if (catid) {
  //     getSubCategoriesBySequence();
  //   }
  // }, []);

  return toLoad.includes(index - 1) &&
    !Boolean(subCategoriesBySequence.length) ? (
    <>
      <div
        style={{
          //   backgroundImage: `url(${bannerImg})`,
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
          borderRadius: "3px",
          width: "100%",
          height: "60vw",
        }}
        loading="eager"
      >
        <Skeleton
          style={{
            width: "100vw",
            height: "100%",
          }}
        />
      </div>
      <Grid container spacing={2} sx={ViewCategoryGrid}>
        {[1, 2, 3].map((i) => (
          <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
            <Skeleton
              style={{
                width: "40vw",
                height: "40vw",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  ) : Boolean(subCategoriesBySequence.length) ? (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        // boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: "3px",
      }}
    >
      <>
        {/* {catid && (
          <Link
            to={`/subcategory/${catname}`}
            state={{ catid: catid }}
            style={{
              textDecoration: "none",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
              borderRadius: "3px",
            }}
            key={categories[0]?._id ?? "#"}
          >
            <div
              className="banner_16_9"
              style={{
                backgroundImage: `url(${bannerImg})`,
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
                borderRadius: "3px",
              }}
              loading="eager"
            ></div>
          </Link>
        )} */}

        {/* ) : ( */}
        {displayItems ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // ensures vertical alignment
                padding: "10px",
                backgroundColor: "#fff",
                fontWeight: "600",
                fontFamily: "Quicksand",
                fontSize: "16px",
                borderRadius: "3px",
                marginBottom: "10px", // optional for spacing
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: "1.5" }}
                //   key={"subtitle1"}
              >
                {catid ? catname : "View by category"}
              </Typography>
              <Link
                to={`/subcategory/${catname}`}
                state={{ catid: catid }}
                style={ViewAllLink}
              >
                View All
              </Link>
            </Box>

            <Grid container spacing={2} sx={ViewCategoryGrid}>
              {
                // i had kept this as i>5 to restrict only 6 categories removed it temporarily
                // categories &&
                // categories?.filter((category) => {
                //   return catid
                //     ? toDisplay.below.includes(category._id)
                //     : true;
                // })?.map((currcategory, i) => {
                //   console.log("currrrrr", currcategory);
                //   return (
                //     currcategory.imageUrl != "noimage" && (
                //       <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
                //         <CategoryCard
                //           categoryname={currcategory.name}
                //           categoryid={currcategory._id}
                //           categoryimage={currcategory.imageUrl}
                //           propid={catid}
                //           key={i}
                //           setLoadCount={setLoadCount}
                //           subcategory={currcategory}
                //         />
                //       </Grid>
                //     )
                //   );
                //   // ) : null;
                // })
                subCategoriesBySequence?.slice(0, 6).map((currcategory, i) => {
                  return (
                    currcategory.imageUrl != "noimage" && (
                      <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
                        <CategoryCard
                          categoryname={currcategory.name}
                          categoryid={currcategory._id}
                          categoryimage={currcategory.imageUrl}
                          propid={catid}
                          key={i}
                          setLoadCount={setLoadCount}
                          subcategory={currcategory}
                        />
                      </Grid>
                    )
                  );
                  // ) : null;
                })
              }
            </Grid>
          </>
        ) : (
          <>
            {/* <Grid container spacing={2} sx={ViewCategoryGrid}>
              {[1, 2, 3].map((i) => (
                <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
                  <Skeleton
                    style={{
                      width: "40vw",
                      height: "40vw",
                    }}
                  />
                </Grid>
              ))}
            </Grid> */}
          </>
        )}
        {/* )} */}
      </>{" "}
    </Box>
  ) : null;
};

export default ViewCategory;
