import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Plus from "../../../assets/plus.svg";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import ImageUpload from "../Inventory/Layout/ImageUpload";
import BannerTable from "./Layout/BannerTable";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Banners = () => {
  const [offers, setOffers] = useState([]);
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const { setLoading } = context;

  //addbanner modal states
  const [imageFile, setImageFile] = useState();
  const [oldbanner, setoldbanner] = useState();

  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState(false);
  const [newtitle, setNewTitle] = useState();
  const [active, setActive] = useState(false);

  // const [newdesc, setnewdesc] = useState()
  // const [newdiscount, setnewdiscount] = useState()

  //storing all cat , subcat , prod in following 3 states
  const [categories, setCategories] = useState();
  const [subcategories, setSubCategories] = useState();
  const [products, setproducts] = useState();

  const [catnames, setCatNames] = useState();
  const [subcatnames, setSubCatNames] = useState();

  //storing selected cat/subcat/prod indices in these states
  const [selectedcat, setSelectedCat] = useState();
  const [selectedsubcat, setSelectedSubCat] = useState();
  const [selectedproduct, setselectedproduct] = useState();

  //default values used in case edit is on
  const [defaultcat, setdefaultcat] = useState();
  const [defaultsubcat, setdefaultSubcat] = useState();
  const [defaultprod, setdefaultprod] = useState();

  const [choice, setchoice] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setedit(false);
    // setActive(false)
    setoldbanner();
    setImageFile();

    setSelectedCat();
    setSelectedSubCat();
    setselectedproduct();

    setchoice(0);

    setdefaultcat();
    setdefaultprod();
    setdefaultSubcat();
    getOffers();
  };

  const getOffers = async () => {
    setLoading(true);
    const result = await axios.get(`${server}/banners`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
      },
    });
    // console.log(result.data);
    setOffers(result.data);
    setLoading(false);
  };

  const getcatandsubcat = async () => {
    //this api gets all categories
    const result = await axios.get(`${server}/public/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
      },
    });
    // console.log(result.data)
    setCategories(result.data);
    let arr = [];
    result.data.forEach((element, i) => {
      arr.push(element.name);
      if (i === result.data.length - 1) {
        // console.log(arr)
        setCatNames(arr);
      }
    });

    //this api gets all subcategories
    const subresult = await axios.get(`${server}/subcategories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
      },
    });
    setSubCategories(subresult.data);

    //this api gets all products
    const prodresult = await axios.get(`${server}/admin/products`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
      },
    });
    // console.log(result.data)
    setproducts(prodresult.data);
  };

  const CreateOffer = async () => {
    if (edit) {
      //update offer apit
      const editedoffer = await axios.post(
        `${server}/banner/edit`,
        {
          bannerid: oldbanner._id,
          title: newtitle ? newtitle : oldbanner ? oldbanner.title : null,
          // "message": newdesc ? newdesc : oldbanner ? oldbanner.message : null,
          image: imageFile,
          // "discount": newdiscount ? newdiscount : oldbanner ? oldbanner.title : null,
          productId: products[selectedproduct]?._id || null,
          categoryId: categories[selectedcat]?._id || null,
          subcategoryId: subcategories[selectedsubcat]?._id || null,
          active: active,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
    } else {
      //create offer api
      const newoffer = await axios.post(
        `${server}/banner/create`,
        {
          title: newtitle,
          // "message": newdesc,
          image: imageFile,
          // "discount": newdiscount,
          productId: products[selectedproduct]?._id,
          categoryId: categories[selectedcat]?._id,
          subcategoryId: subcategories[selectedsubcat]?._id,
          active: active,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
    }
    setImageFile();
    handleClose();

    // getOffers()
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      getOffers();
      getcatandsubcat();
    }
  }, []);

  useEffect(() => {
    // console.log(imageFile)
  }, [offers, imageFile, oldbanner]);

  useEffect(() => {
    // console.log(selectedcat + " " + selectedproduct)
  }, [
    categories,
    subcategories,
    catnames,
    subcatnames,
    newtitle,
    selectedcat,
    selectedproduct,
  ]);

  useEffect(() => {
    // console.log('default cat is ' + defaultcat + " " + defaultprod)
    if (categories && defaultcat !== null) {
      categories.map((element, i) => {
        if (element._id === defaultcat) {
          // console.log("is a category " + element.name)
          setSelectedCat(i);
          console.log("old category number is " + i);
          setselectedproduct();
          setSelectedSubCat();
          setchoice(0);
          // console.log("should open modal")

          handleOpen();
        }
      });
    }
  }, [defaultcat]);

  useEffect(() => {
    if (products && defaultprod !== null) {
      products.map((element, i) => {
        if (element._id === defaultprod) {
          // console.log("is a product " + element.name)
          setselectedproduct(i);
          setSelectedCat();
          setSelectedSubCat();
          setchoice(1);
          // console.log("should open modal")

          handleOpen();
        }
      });
    }
  }, [defaultprod]);

  useEffect(() => {
    if (subcategories && defaultsubcat !== null) {
      subcategories.map((element, i) => {
        if (element._id === defaultsubcat) {
          // console.log("is a product " + element.name)
          setSelectedSubCat(i);
          setSelectedCat();
          setselectedproduct();
          // console.log("should open modal")
          setchoice(2);
          handleOpen();
        }
      });
    }
  }, [defaultsubcat]);
  const [inputValue, setInputValue] = useState("");

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Banner Summary</p>

        <button
          onClick={(e) => {
            e.preventDefault();
            handleOpen();
          }}
        >
          <img alt="plus" src={Plus} loading="eager" />
          Add a New Banner
        </button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50vw",
              bgcolor: "background.paper",
              borderRadius: "12px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: 1,
              }}
            >
              <Typography
                sx={{
                  marginBottom: "10px",
                  fontWeight: 500,
                }}
              >
                {" "}
                {edit ? <> Edit Offer</> : <> Create New Offer</>}
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "80%" }}>
                <ImageUpload
                  newProduct={[]}
                  // setNewProduct={}
                  setImageFile={setImageFile}
                  imagesSave={typeof imageFile === "string" ? imageFile : ""}
                  isEdit={false}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                  height: "100%",
                }}
              >
                <TextField
                  // value={ newproductprice}
                  // className="product-input1"
                  label="Title"
                  defaultValue={oldbanner && oldbanner.title}
                  // variant="outlined"
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    marginLeft: "5px",
                    backgroundColor: "#EFF1F9",
                  }}
                  // placeholder="Product MRP"
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                />

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={choice}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      setchoice(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Category"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Product"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="SubCategory"
                    />
                  </RadioGroup>
                </FormControl>

                {catnames && choice == 0 ? (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    sx={{ margin: 1 }}
                  >
                    <InputLabel id="test-select-label">Category</InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        marginTop: "5px",
                        marginLeft: "5px",
                        backgroundColor: "#EFF1F9",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={}
                      defaultValue={selectedcat}
                      label="Select Category"
                      onChange={(e) => {
                        if (selectedproduct !== null) setselectedproduct();
                        if (selectedsubcat !== null) setSelectedSubCat();

                        setSelectedCat(e.target.value);
                      }}
                    >
                      {catnames &&
                        catnames.map((element, i) => (
                          <MenuItem value={i}>
                            {element} key={i}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}

                {products && choice == 1 ? (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin={"1"}
                  >
                    {/* <InputLabel
											id="test-select-label"
											sx={{
												alignItems: "center",
											}}
										>
											Product
										</InputLabel> */}
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={products}
                      getOptionLabel={(option) => option.name}
                      sx={{
                        width: "100%",
                        marginTop: "5px",
                        marginLeft: "5px",
                        backgroundColor: "#EFF1F9",
                      }}
                      onChange={(e) => {
                        if (selectedcat !== null) setSelectedCat();
                        if (selectedsubcat !== null) setSelectedSubCat();
                        setselectedproduct(e.target.value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Product" />
                      )}
                    />
                  </FormControl>
                ) : (
                  <></>
                )}

                {catnames && choice == 2 ? (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin={"1"}
                  >
                    <InputLabel id="test-select-label">SubCategory</InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        marginTop: "5px",
                        marginLeft: "5px",
                        backgroundColor: "#EFF1F9",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={}
                      defaultValue={selectedsubcat}
                      label="Select SubCategory"
                      onChange={(e) => {
                        if (selectedproduct !== null) setselectedproduct();
                        if (selectedcat !== null) setSelectedCat();

                        setSelectedSubCat(e.target.value);
                      }}
                    >
                      {subcategories &&
                        subcategories.map((element, i) => {
                          return <MenuItem value={i}>{element.name}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}

                <div
                  className="cash-on-delivery"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  {/* <Stack direction="row" spacing={1} alignItems="center"> */}
                  <p className="cash-text">Active</p>
                  <AntSwitch
                    defaultChecked={oldbanner ? oldbanner.active : false}
                    onChange={(e) => {
                      if (e.target.checked) setActive("true");
                      else setActive("false");
                    }}
                    inputProps={{
                      "aria-label": "ant design",
                    }}
                  />
                  {/* </Stack> */}
                </div>

                <Button
                  sx={{
                    backgroundColor: "#5570F1",
                    color: "white",
                    fontWeight: "500",
                    marginTop: "10px",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#5570F1",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    CreateOffer();
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
      <div>
        <BannerTable
          offers={offers}
          setoldbanner={setoldbanner}
          setedit={setedit}
          setopen={setOpen}
          setImageFile={setImageFile}
          setdefaultcat={setdefaultcat}
          setdefaultprod={setdefaultprod}
          setdefaultSubcat={setdefaultSubcat}
          setActive={setActive}
          getOffers={getOffers}
        />
      </div>
    </div>
  );
};

export default Banners;
