import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import flash from "../../../../assets/flash_icon.png";
import { ReactComponent as Search } from "../../../../assets/search.svg";
import { useQuickCommerce } from "../../context/QuickCommerceProvider";

const InputPropsStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none!important",
    },
    input: {
      "&::placeholder": {
        color: "#5EC401 !important",
        fontSize: "14px",
        fontFamily: "poppins",
        fontWeight: 400,
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
};

const SearchInputStyle = {
  backgroundColor: "#F5F5F5",
  borderRadius: "8px 8px 0px 0px",
  width: "100%",
  margin: "10px 0px",
};

const InputLabelProps = {
  sx: {
    color: "#5EC401",
    textTransform: "capitalize",
  },
};

const CustomSwitch = styled(Switch)({
  width: "53px",
  height: "29px",
  padding: 0,
  borderRadius: "16px",
  "& .MuiSwitch-switchBase": {
    padding: 1,
    // transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F37A20",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: "28px",
    height: "25px",
    backgroundImage: `url(${flash})`,
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#EAEAEA",
    borderRadius: "50%",
  },
  "& .MuiSwitch-track": {
    borderRadius: "16px 0px 0px 0px",
    backgroundColor: "#EAEAEA",
    opacity: 1,
    transition: "background-color 300ms",
  },
});

const textStyle = {
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "italic",
  fontWeight: 600,
  // lineHeight: "11px",
  // textAlign: "right",
  color: "#FFFFFF",
  // marginLeft: "10px",
  marginLeft: "30px",
};

const Header = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const { quickCommerce, setQuickCommerce } = useQuickCommerce();

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    if (isChecked) {
      setQuickCommerce("QuickCommerce");
    } else {
      setQuickCommerce("Regular");
    }
  };

  const handleSearch = () => {
    navigate("/search");
  };

  useEffect(() => {
    setChecked(quickCommerce === "QuickCommerce");
  }, [quickCommerce]);

  return (
    <Box sx={{ backgroundColor: "#5EC401", padding: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 10px",
          marginLeft: "auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            color: "#FFFFFF",
            fontFamily: "Poppins",
          }}
        >
          Urban Bazaar
        </Typography>

        <Typography sx={textStyle}>
          Quick Delivery
          {/* {checked ? "On" : "Off"} */}
        </Typography>
        <FormControlLabel
          control={<CustomSwitch checked={checked} onChange={handleChange} />}
          // label={checked ? "On" : "Off"}
          sx={{ marginRight: 0, padding: 0 }}
        />
      </Box>

      <TextField
        size="small"
        placeholder="Search"
        sx={SearchInputStyle}
        InputProps={InputPropsStyle}
        InputLabelProps={InputLabelProps}
        onClick={handleSearch}
      />
    </Box>
  );
};

export default Header;
