import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import server from "../../../server";
import { useQuickCommerce } from "../../context/QuickCommerceProvider";
import ProductCard from "./ProductCard";

const ViewCategoryText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
};

const SimilarProductsBox = {
  // backgroundColor: "#F2F2F2",
  // flexGrow: 1,
  paddingBottom: "10px",
  height: "fit-content",
};

const SimilarProductsBoxInner = {
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
};

const SimilarProductsBoxInnerViewAllText = {
  color: "#407EC7",
  fontFamily: "poppins",
  fontWeight: 500,
  fontSize: "12px",
};

const SimilarProductsTitleBox = {
  justifyContent: "space-between",
  display: "flex",
  padding: "10px",
};

const CarouselBox = {
  overflow: "auto",
  padding: "10px 10px 10px 10px",
};

const ViewAllLink = { textDecoration: "none", color: "black" };

const SimilarProducts = (props) => {
  const [products, setProducts] = useState([]);
  const [category, setcategory] = useState({});
  const [defaultsubcat, setdefaultsubcat] = useState([]);
  const { quickCommerce } = useQuickCommerce();

  const getproductsbycategory = async () => {
    const result = await axios.get(
      `${server}/public/products/byCategoryId/${props.categoryid}?productType=${quickCommerce}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setProducts(result?.data);
  };

  const getproductsbySubCategory = async () => {
    const result = await axios.get(
      `${server}/public/products/bySubCategoryId/${props.subCategoryId}?productType=${quickCommerce}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setProducts(result?.data);
  };

  const getcategoryInfo = async () => {
    try {
      const result = await axios.get(
        `${server}/public/category/${props.categoryid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setcategory(result.data);
    } catch (error) {
      console.log(error.request.responseURL, error.message);
    }

    try {
      const subresult = await axios.get(
        `${server}/public/subcategory/find/${props.categoryid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setdefaultsubcat(subresult?.data);
    } catch (error) {
      //   console.log(error.request.responseURL, error.message);
    }
  };

  const getsubcategoryInfo = async () => {
    try {
      const result = await axios.get(
        `${server}/public/subcategory/${props.subCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setcategory(result.data);
    } catch (error) {
      console.log(error.request.responseURL, error.message);
    }
  };

  //   useEffect(() => {
  //     // console.log(Boolean(props?.categoryid));
  //     if (props?.categoryid) {
  //       getproductsbycategory();
  //       getcategoryInfo();
  //     } else if (props?.subCategoryId) {
  //       getsubcategoryInfo() && getproductsbySubCategory();
  //     }
  //     // console.log(props);
  //   }, [props.categoryId, props.subCategoryId]);

  //   useEffect(() => {
  //     console.log("category:", category);
  //   }, [category]);

  const startLoad = () => {
    if (props?.categoryid) {
      getproductsbycategory();
      getcategoryInfo();
    } else if (props?.subCategoryId) {
      getsubcategoryInfo();
      getproductsbySubCategory();
    }
    !props.toLoad.includes(props.index + 1) &&
      props.setToLoad([...props.toLoad, props.index + 1]);
    //   setTimeout(
    //     () => props.setToLoad([...props.toLoad, props.index + 1]),
    //     2000,
    //   );
  };

  useEffect(() => {
    props.toLoad.includes(props.index) &&
      !Boolean(products.length) &&
      startLoad();
  }, [props.toLoad, props.categoryId, props.subCategoryId]);

  return !props.toLoad.includes(props.index) &&
    props.toLoad.includes(props.index - 1) ? (
    <>
      <Box style={CarouselBox}>
        <Grid container spacing={2} wrap="nowrap">
          {[1, 2].map((i) => (
            <Grid item key={i}>
              <Skeleton
                style={{
                  width: "40vw",
                  height: "400px",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  ) : (
    <>
      <Box sx={SimilarProductsBox}>
        {defaultsubcat.length > 0 ? (
          <Box sx={SimilarProductsBoxInner}>
            <Typography variant="subtitle1" sx={ViewCategoryText}>
              Similar Products
            </Typography>
            {/* //the link is actually /categoryproducts/subcatname/subid/catid */}
            <Link
              to={`/categoryproducts/${defaultsubcat[0].name}/${defaultsubcat[0]._id}/${props.categoryid}`}
              state={{
                categoryId: defaultsubcat[0].categoryId._id,
                subcategoryId: defaultsubcat[0]._id,
                name: defaultsubcat[0].name,
              }}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="subtitle2"
                sx={SimilarProductsBoxInnerViewAllText}
              >
                View all
              </Typography>
            </Link>
          </Box>
        ) : (
          <></>
        )}
        {Boolean(products.length) && (
          <>
            <Box sx={SimilarProductsTitleBox}>
              <Typography
                variant="subtitle1"
                sx={{ ...ViewCategoryText, lineHeight: "1.5" }}
                key={"subtitle1"}
              >
                {category?.name}
              </Typography>

              <Link
                to={`/categoryproducts/${category?.categoryId?.name}/${category?.name}`}
                state={{
                  categoryId: category?.categoryId?._id,
                  subcategoryId: category?._id,
                  name: category?.name,
                }}
                style={ViewAllLink}
              >
                View All
              </Link>
            </Box>

            <Box style={CarouselBox}>
              <Grid
                container
                spacing={2}
                wrap="nowrap" // --> add this line to disable wrap
              >
                {products &&
                  products.map((currproduct) => {
                    {
                      // props?.subCategoryId &&
                      // console.log(currproduct);
                    }
                    return currproduct.published &&
                      currproduct.stocklimit &&
                      currproduct.stocklimit < currproduct.stock ? (
                      <Grid item key={currproduct._id}>
                        <ProductCard proddetails={currproduct} />
                      </Grid>
                    ) : null;
                  })}
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SimilarProducts;
